import React, { Component } from 'react';
import { getBore, getCrewSchedulesForJob, getCrews } from '../../../shared/Api';
import { AuthContext } from "../../../shared/Auth";
import Loader from '../../../components/Layout/Loader/Loader';
import * as moment from 'moment';
import { Link } from 'react-router-dom';
import { parseFromFirebaseDate, showToast } from '../../../shared/Util';
import { db, functions, storage } from "../../../firebase";
import { CSVLink } from 'react-csv';
import BoreReport from '../../../components/Reports/BoreReport';
import BoreLogProfileReport from '../../../components/Reports/BoreLogProfileReport';
import { getAllCrewMembers } from '../../../shared/api/CrewApi';
import GoogleMapReact from "google-map-react";
import EQMarker from '../../../components/GoogleMap/EQMarker';
import { buildBoreLogFileEntityPath, buildBoreLogFilesPath } from '../../../shared/data/bore/BoreEntity';
import { toFileEntityDto } from '../../../shared/data/folder/FolderStructure';
import { getFileData } from '../../../shared/api/FilesApi';
import FinishBoreModal from './FinishBoreModal';
import { finishBore } from '../../../shared/api/BoreApi';
import DailyBoreReport from '../../../components/Reports/DailyBoreReport';

class BoresDetails extends Component {
  static contextType = AuthContext;
  user = this.context.currentUser.userProfile;
  canEdit = this.user.permissions !== "crewmember";

  state = {
    bore: null,
    loading: true,
    showFullOverview: false,
    showFinishBoreDialog: false,
    showDailyLogs: null,
    boreReportSending: false,
    boreLogProfileReportSending: false,
    boreLogReportSending: false,
    assignedCrewmeberIds: null,
    crewMembers:[]
  };

  reportHeaders = () => {
    return [
      { label: "Entry#", key: "entryID" },
      { label: "Time", key: "date" },
      { label: "Distance Since Last Entry", key: "distance" },
      { label: "Depth", key: "depth" },
      { label: "Pitch", key: "pitch" },
      { label: "GPS Coordinates", key: "coordinates" },
      { label: "Notes", key: "notes" },
    ];
  };

  reportData = () => {
    return this.state.bore.boreLogs.map((boreLog, index) => {
      return {
        entryID: index + 1,
        date: parseFromFirebaseDate(boreLog.date, "MM/DD/YY hh:mm a"),
        distance: `${boreLog.distance}'`,
        depth: `${boreLog.depth??0}''`,
        pitch: `${boreLog.pitch??0}%`,
        coordinates: `${boreLog.lat??this.state?.bore.lat}, ${boreLog.long??this.state?.bore.long}`,
        notes: boreLog.notes,
      };
    }).sort((a, b)=>{
        return new Date(a.date)-new Date(b.date)
    });
  };

  showFinishBoreDialog = (show) => {
    this.setState({ showFinishBoreDialog: show });
  };

  componentDidMount = async () => {
    this.setState({ loading: true });
    await this.loadBore();
    await this.loadAssignedCrewmembers();
    await this.fetchCrewMembers();
    this.setState({ loading: false });
  };

  fetchCrewMembers = async () => {
    const inventoryId = this.context.currentUser.company.inventoryID;

    const crewMembers = await getAllCrewMembers(
      inventoryId,
    );
    const filteredCrewMembers = crewMembers.data.filter((crewMember)=>crewMember.email!==this.user.email)
    this.setState({crewMembers:filteredCrewMembers})
  }

  loadBore = async () => {
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const boreId = this.props.match.params.boreId;
    const companyId = this.context.currentUser.userProfile.companyID;

    const boreResult = await getBore(inventoryId, boreId);

    await this.updateBoreLogsWithNewFileSystem(boreResult?.data?.boreLogs, boreId, inventoryId, companyId)

    const crews = await getCrews(inventoryId);
    const bore = boreResult.data;
    bore.crew = crews.data.find((x) => x.id === bore.crewID);
    let days = [];
    let progress = 0;

    bore.boreLogs.forEach((boreLog) => {
      const date = boreLog.strippedDate;
      let day = days.find((x) => x.date === date);
      if (!day) {
        day = {
          date: date,
          logs: [boreLog],
          totalDistance: boreLog.distance,
          totalPitch: boreLog.pitch,
          totalDepth: boreLog.depth
        };

        days.push(day);
      } else {
        day.logs.push(boreLog);
        day.totalDistance += boreLog.distance;
        day.totalPitch = boreLog.pitch;
        day.totalDepth = boreLog.depth;
      }
      progress += boreLog.distance;
    });
    bore.days = days.sort(function (a, b) {
      return new Date(b.date) - new Date(a.date);
    });

    bore.days.forEach((element) => {
      element.logs = element.logs.sort(function (a, b) {
        return a.boreLogNumber - b.boreLogNumber;
      });
    })
    
    // revers order of days so that total distance can be calculated
    let distanceBored = 0
    bore.days?.reverse().forEach((day)=>{
      distanceBored += day.totalDistance
      day.distanceBored  = distanceBored
    });
    // array is reversed back in original sorting position
    bore.days?.reverse()

    bore.boreProgress = progress;

    this.setState({ bore: bore });
  };


   //This method is used for updating bore logs to new file system. It should be deleted after 6 months of implementations considering that there will be no need for this since all bore logs are already updated with new file system.
   updateBoreLogsWithNewFileSystem = async (boreLogs, boreId, inventoryId, companyId) => {

    await Promise.all(boreLogs?.map( async (boreLog)=>{

      const boreLogFiles = await getFileData(
        buildBoreLogFileEntityPath(inventoryId, boreId, boreLog.id),
        buildBoreLogFilesPath(companyId, boreLog.id)
      )

      //if there are no files already created and imageTimeStamp is not null continue with creation of new file structure
        if ((boreLogFiles.data ?? []).length < 1 && boreLog.imageTimeStamp) {
          try{
            const storageRef = storage.ref('/' + companyId + '/boreLog/' + boreLog.id + '/' + boreLog.imageTimeStamp.seconds + '/');
            const url = await storageRef.child('med').getDownloadURL();
        
            const fileEntity = {
                id: null,
                name: null,
                fileName: null,
                createdBy: null,
                fileType: "image",
                createdAt: boreLog.imageTimeStamp,
                lat: boreLog?.lat ?? null,
                lng: boreLog?.long ?? null,
                url: url ?? null
            }
        const fileEntityPath = buildBoreLogFileEntityPath(
            inventoryId,
            boreId,
            boreLog.id
          )
        const result = await db.collection(fileEntityPath).add(toFileEntityDto(fileEntity))
          }catch(error){
            console.error(error)
          }
        }
    }))
}

  finishBore = async (duration, footage) => {
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const boreId = this.props.match.params.boreId;

    const result = await finishBore(inventoryId, boreId, duration, footage, currentUser?.displayName ?? "")

    showToast("success", "Bore completed");

    this.setState({ bore: { ...this.state.bore, finished: true } });
  };

  loadAssignedCrewmembers = async () => {
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const jobId = this.props.match.params.id;

    const schedulesResult = await getCrewSchedulesForJob(inventoryId, jobId);
    const assignedCrewmeberIds = schedulesResult.data.map(
      (x) => x.crewMemberID
    );

    this.setState({ assignedCrewmeberIds: assignedCrewmeberIds });
  };

  canAddBorLog = () => {
    if (this.user.permissions !== "crewmember") return true;

    return (
      this.state.assignedCrewmeberIds &&
      this.state.assignedCrewmeberIds.includes(this.user.crewmemberID)
    );
  };

  reportBtnClass = (className, flag) => {
    if (flag) className += " spinner spinner-white spinner-right";

    return className;
  };

  shareLogEntry = async (strippedDate, distance) => {
    // let sendBoreReportFunc = functions.httpsCallable("boreLogReport");

    // let result = await sendBoreReportFunc({
    //   userID: this.context.currentUser.uid,
    //   boreID: this.state.bore.id,
    //   jobID: this.state.bore.jobID,
    //   strippedDate: strippedDate,
    //   boreNo: this.state.bore.boreNumber,
    //   distance: this.state.bore.boreProgress,
    //   distanceToday: distance,
    // });
    
    showToast("success", "Report has been sent successfully.");
  };

  render() {
    if (this.state.loading) return <Loader />;
    return (
      <div className="card">
        <div className="card-body">
          <div className="row my-10 px-8 my-lg-15 px-lg-10">
            <div className="col-xl-12">
              <div className="mb-12">
                <h3 className="mb-10 font-weight-bold text-dark d-inline">
                  Bore Overview
                </h3>
                {this.canAddBorLog() && (
                  <Link
                    to={
                      "/jobs/" +
                      this.props.match.params.id +
                      "/bores/" +
                      this.props.match.params.boreId +
                      "/borelogs/new"
                    }
                    className="btn btn-primary font-weight-bold mr-4 float-right ml-4"
                  >
                    Add New Bore Log
                  </Link>
                )}

                <BoreReport
                  crewMembers={this.state.crewMembers}
                  jobId={this.state.bore.jobID}
                  boreId={this.state.bore.id}
                  distanceRemaining={
                    (this.state.bore.boreLength -
                    this.state.bore.boreProgress) +
                    " ft"
                  }
                />

                <BoreLogProfileReport
                  crewMembers={this.state.crewMembers}
                  jobId={this.state.bore.jobID}
                  boreId={this.state.bore.id}
                />
              </div>
              <div className="bg-light rounded card-rounded flex-grow-1 pt-10 pr-10 pl-10 pb-4">
                <a className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">
                  Overall Bore Progress
                </a>
                <div className="mb-12">
                  <div className="row m-0">
                    <div className="col px-8 py-6">
                      <div className="font-size-h6 text-muted font-weight-bold mb-2">
                        as of
                      </div>
                      <div className="font-size-h6 font-weight-bolder">
                        {this.state.bore.days.length > 0
                          ? moment(this.state.bore.days[0].date).format(
                              "MM/DD/YY"
                            )
                          : moment().format("MM/DD/YY")}
                      </div>
                      <div className="font-size-h6 font-weight-bolder">
                        {moment().format("hh:mm a")}
                      </div>
                    </div>
                    <div className="col px-8 py-6">
                      <div className="font-size-h6 font-weight-bold mb-4">
                        {this.state.bore.boreProgress
                          ? this.state.bore.boreProgress
                          : 0}{" "}
                        ft
                      </div>
                      <div className="font-size-h6 text-muted font-weight-bolder">
                        Total
                      </div>
                      <div className="font-size-h6 text-muted font-weight-bolder">
                        Distance
                      </div>
                      <div className="font-size-h6 text-muted font-weight-bolder">
                        Completed
                      </div>
                    </div>
                    <div className="col px-8 py-6">
                      <div className="font-size-h6 font-weight-bold mb-4">
                        {this.state.bore.boreProgress
                          ? (
                              this.state.bore.boreLength -
                              this.state.bore.boreProgress
                            ).toFixed(2)
                          : this.state.bore.boreLength}{" "}
                        ft
                      </div>
                      <div className="font-size-h6 text-muted font-weight-bolder">
                        Distance
                      </div>
                      <div className="font-size-h6 text-muted font-weight-bolder">
                        Remaining
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="form-group row pl-10 mb-0 mt-0">
                      <label className="col-xl-3 col-lg-3 font-weight-bolder col-form-label">
                        Bore Length
                      </label>
                      <div className="col-lg-9 col-xl-6">
                        <span className="form-control form-control-lg form-control-solid">
                          {this.state.bore.boreLength} ft
                        </span>
                      </div>
                    </div>
                    <div className="form-group row pl-10 mb-0 mt-0">
                      <label className="col-xl-3 col-lg-3 font-weight-bolder col-form-label">
                        Crew
                      </label>
                      <div className="col-lg-9 col-xl-6">
                        <span className="form-control form-control-lg form-control-solid">
                          {this.state.bore.crew
                            ? this.state.bore.crew.crewName
                            : "Not assigned"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.showFullOverview && (
                  <>
                    <div style={{ height: "40vh", width: "100%" }}>
                      <GoogleMapReact
                        options={{
                          mapTypeControl: true,
                          mapTypeId: "satellite",
                        }}
                        bootstrapURLKeys={{
                          key: process.env.REACT_APP_GOOGLE_MAP_KEY ?? "",
                        }}
                        center={{
                          lat: this.state.bore.lat,
                          lng: this.state.bore.long,
                        }}
                        zoom={14}
                        yesIWantToUseGoogleMapApiInternals={true}
                        onGoogleApiLoaded={({ map, maps }) => {}}
                      >
                        {this.state.bore && (
                          <EQMarker
                            key={103}
                            lat={this.state.bore?.lat}
                            lng={this.state.bore?.long}
                            pinColor={"darkgreen"}
                          />
                        )}
                      </GoogleMapReact>
                    </div>
                    <div className="separator separator-dashed mt-3 mb-2"></div>
                    <div className="form-group row pl-10 mb-0 mt-0">
                      <label className="col-xl-3 col-lg-3 font-weight-bolder col-form-label">
                        Bore No.
                      </label>
                      <div className="col-lg-9 col-xl-6">
                        <span className="form-control form-control-lg form-control-solid">
                          #{("000" + this.state.bore.boreNumber).substr(-3)}
                        </span>
                      </div>
                    </div>
                    <div className="form-group row pl-10 mb-0 mt-0">
                      <label className="col-xl-3 col-lg-3 font-weight-bolder col-form-label">
                        Start Date
                      </label>
                      <div className="col-lg-9 col-xl-6">
                        <span className="form-control form-control-lg form-control-solid">
                          {moment(this.state.bore.startDate.toDate()).format(
                            "MM/DD/YY"
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="form-group row pl-10 mb-0 mt-0">
                      <label className="col-xl-3 col-lg-3 font-weight-bolder col-form-label">
                        Location
                      </label>
                      <div className="col-lg-9 col-xl-6">
                        <span className="form-control form-control-lg form-control-solid">
                          {this.state.bore.lat} , {this.state.bore.long}
                        </span>
                      </div>
                    </div>

                    <div className="form-group row pl-10 mb-0 mt-0">
                      <label className="col-xl-3 col-lg-3 font-weight-bolder col-form-label">
                        Expected Soil Conditions
                      </label>
                      <div className="col-lg-9 col-xl-6">
                        <span className="form-control form-control-lg form-control-solid">
                          {this.state.bore.soilConditions}
                        </span>
                      </div>
                    </div>
                    <div className="form-group row pl-10 mb-0 mt-0">
                      <label className="col-xl-3 col-lg-3 font-weight-bolder col-form-label">
                        Time Boring
                      </label>
                      <div className="col-lg-9 col-xl-6">
                        <span className="form-control form-control-lg form-control-solid">
                          ----
                        </span>
                      </div>
                    </div>
                    <div className="form-group row pl-10 mb-0 mt-0">
                      <label className="col-xl-3 col-lg-3 font-weight-bolder col-form-label">
                        Distance Completed
                      </label>
                      <div className="col-lg-9 col-xl-6">
                        <span className="form-control form-control-lg form-control-solid">
                          {this.state.bore.boreProgress} ft
                        </span>
                      </div>
                    </div>
                    <div className="form-group row pl-10 mb-0 mt-0">
                      <label className="col-xl-3 col-lg-3 font-weight-bolder col-form-label">
                        Distance Remaining
                      </label>
                      <div className="col-lg-9 col-xl-6">
                        <span className="form-control form-control-lg form-control-solid">
                          {this.state.bore.boreLength -
                            this.state.bore.boreProgress}{" "}
                          ft
                        </span>
                      </div>
                    </div>
                    <div className="form-group row pl-10 mb-0 mt-0">
                      <label className="col-xl-3 col-lg-3 font-weight-bolder col-form-label">
                        Start station #
                      </label>
                      <div className="col-lg-9 col-xl-6">
                        <span className="form-control form-control-lg form-control-solid">
                          {this.state.bore.startStation}
                        </span>
                      </div>
                    </div>
                    <div className="form-group row pl-10 mb-0 mt-0">
                      <label className="col-xl-3 col-lg-3 font-weight-bolder col-form-label">
                        End station #
                      </label>
                      <div className="col-lg-9 col-xl-6">
                        <span className="form-control form-control-lg form-control-solid">
                          {this.state.bore.endStation}
                        </span>
                      </div>
                    </div>
                    <div className="separator separator-dashed mt-3 mb-2"></div>
                    <div className="form-group row pl-10 mb-0 mt-0">
                      <div className="col-lg-9 col-xl-6">
                        {this.state.bore?.products?.map((product, index) => {
                          return (
                            <div>
                              <label className="font-weight-bolder">
                                {`Product: #${index + 1}`}
                                <span className="form-control form-control-lg form-control-solid">
                                  {`Type: ${product.type}, Size: ${product.size} in, Quantity: ${product.quantity}`}
                                </span>
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="form-group row pl-10 mb-0 mt-0">
                      <label className="col-xl-3 col-lg-3 font-weight-bolder col-form-label">
                        Notes
                      </label>
                      <div className="col-lg-9 col-xl-6">
                        <span className="form-control form-control-lg form-control-solid">
                          {this.state.bore.notes}
                        </span>
                      </div>
                    </div>

                    {!this.state.bore.finished && this.canEdit && (
                      <div className="form-group row pl-10 mb-0 mt-0 mt-2 mb-4">
                        <div className="col-lg-9 col-xl-6">
                          <button
                            onClick={() => {
                              this.setState({ showFinishBoreDialog: true });
                            }}
                            className="btn btn-dark font-weight-bold mr-4 pr-10 pl-10"
                          >
                            Finish bore
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                )}

                <a
                  onClick={() =>
                    this.setState({
                      showFullOverview: !this.state.showFullOverview,
                    })
                  }
                  className="text-dark-50 btn text-hover-primary d-block text-center"
                >
                  {this.state.showFullOverview ? "Show less" : "Show more"}
                </a>
              </div>
              <div className="card-spacer rounded card-rounded flex-grow-1">
                <a className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">
                  Daily Bore Progress
                </a>
                {this.state.bore.days.length > 0 ? (
                  <>
                    {this.state.bore.days.map((item, index) => (
                      <div
                        key={"brs-dtls" + index}
                        className="row m-0 border rounded mt-10"
                      >
                        <div className="col px-8 py-6">
                          <div className="font-size-h6 font-weight-bolder">
                            {moment(item.date).format("MM/DD/YY")}
                          </div>
                        </div>
                        <div className="col px-8 py-6">
                          <div className="font-size-h6 font-weight-bold mb-4">
                            {item.totalDistance} ft
                          </div>
                          <div className="font-size-h6 text-muted font-weight-bolder">
                            Total
                          </div>
                          <div className="font-size-h6 text-muted font-weight-bolder">
                            Distance
                          </div>
                        </div>
                        <div className="col px-8 py-6">
                          <div className="font-size-h6 font-weight-bold mb-4">
                            {item.distanceBored ?? ""} ft
                          </div>
                          <div className="font-size-h6 text-muted font-weight-bolder">
                            Distance
                          </div>
                          <div className="font-size-h6 text-muted font-weight-bolder">
                            Bored
                          </div>
                        </div>
                        <div className="col px-8 py-6">
                          <div className="font-size-h6 font-weight-bold mb-4">
                            {item.logs[item.logs.length - 1].depth} in
                          </div>
                          <div className="font-size-h6 text-muted font-weight-bolder">
                            Depth
                          </div>
                        </div>
                        <div className="col px-8 py-6">
                          <div className="font-size-h6 font-weight-bold mb-4">
                            {item.logs[item.logs.length - 1].pitch} %
                          </div>
                          <div className="font-size-h6 text-muted font-weight-bolder">
                            Pitch
                          </div>
                        </div>
                        <div className="col-1 px-8 py-6">
                          <div
                            className="dropdown dropdown-inline ml-2"
                            data-toggle="tooltip"
                            title="Quick actions"
                            data-placement="left"
                          >
                            <a
                              className="btn btn-hover-light-primary btn-sm btn-icon"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i className="ki ki-bold-more-hor" />
                            </a>
                            <div className="dropdown-menu p-0 m-0 dropdown-menu-md dropdown-menu-right">
                              <ul className="navi navi-hover">
                                <li className="navi-item">
                                  <Link
                                    to={
                                      "/jobs/" +
                                      this.props.match.params.id +
                                      "/bores/" +
                                      this.props.match.params.boreId +
                                      "/borelogs/" +
                                      moment(item.date).unix()
                                    }
                                    className="navi-link btn"
                                  >
                                    <span className="navi-text">
                                      View Daily Bore Log Entries
                                    </span>
                                  </Link>
                                </li>
                                {!this.state.bore.finished && this.canEdit && (
                                  <li className="navi-item">
                                    <a
                                      onClick={this.finishBore}
                                      className="navi-link btn"
                                    >
                                      <span className="navi-text">
                                        Finish Bore From Last Entry
                                      </span>
                                    </a>
                                  </li>
                                )}
                                <li className="navi-item">
                                  <DailyBoreReport
                                  componentId={index}
                                   crewMembers={this.state.crewMembers}
                                   jobId={this.state.bore.jobID}
                                   boreId={this.state.bore.id}
                                   isButton={false}
                                   strippedDate={moment(item.date).format("M/DD/YY")}
                                   boreNumber={this.state.bore.boreNumber}
                                   distance={this.state.bore.boreProgress}
                                   distanceToday={item.totalDistance}/>
                        

                                  {/* <a
                                    onClick={() =>
                                      this.shareLogEntry(
                                        moment(item.date).format("M/DD/YY"),
                                        item.totalDistance
                                      )
                                    }
                                    className="navi-link btn"
                                  >
                                    <span className="navi-text">
                                      Share Daily Bore Log
                                    </span>
                                  </a> */}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    <br />
                    <br />
                    <span className="font-italic">(No items)</span>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <FinishBoreModal
          show={this.state.showFinishBoreDialog}
          onFinishBore={(duration, footage) => {
            this.finishBore(duration, footage);
            this.showFinishBoreDialog(false);
          }}
          onCancel={() => {
            this.showFinishBoreDialog(false);
          }}
        />
      </div>
    );
  }
}

export default BoresDetails;