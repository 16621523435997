import React, { useContext, useEffect, useState } from "react";
import {
  buildJobWalkThroughFileEntityPath,
  buildJobWalkThroughFilesPath,
  JobWalkThroughEntity,
} from "../../../shared/data/job/JobWalkThrough";
import { parseFromFirebaseDate } from "../../../shared/Util";
import { getFileData } from "../../../shared/api/FilesApi";
import { ContextEntity } from "../../../shared/data/context/ContextEntity";
import { AuthContext } from "../../../shared/Auth";
import { FileEntity } from "../../../shared/data/folder/FolderStructure";
import MultipleItems from "../../../components/Carousels/MultipleItems";
import Viewer from "react-viewer";
import moment from "moment";
import { title } from "process";

interface JobWalkThroughDetailsProps {
  jobWalkThroughItem?: JobWalkThroughEntity | null;
  jobId: string;
  jobWalkThroughId: string;
}

interface FormState {
  selectedImageIndex: number;
}

const JobWalkThroughDetails = (props: JobWalkThroughDetailsProps) => {
  const context: ContextEntity = useContext(AuthContext);
  const [files, setFiles] = useState<FileEntity[]>([]);
  const [formState, setFormState] = useState<FormState>();

  const loadFiles = async () => {
    const inventoryId = context.currentUser?.company?.inventoryID ?? "";
    const companyId = context.currentUser?.userProfile?.companyID ?? "";
    const jobId = props.jobId;
    const JobWalkThroughId = props.jobWalkThroughId;

    const walkThroughFiles = await getFileData(
      buildJobWalkThroughFileEntityPath(inventoryId, jobId, JobWalkThroughId),
      buildJobWalkThroughFilesPath(companyId, jobId, JobWalkThroughId)
    );

    const photos = walkThroughFiles?.data?.filter((file)=>file.fileType=='photo' || file.fileType=='image') ?? []

    setFiles(photos);
  };

  useEffect(() => {
    loadFiles();
  }, []);

  return (
    <React.Fragment>
      <div className="card card-custom">
        {/*begin::Header*/}
        <div className="card-header h-auto py-4">
          <div className="card-title">
            <h3 className="card-label">Job Walk Through</h3>
          </div>
          <div className="card-toolbar"></div>
        </div>

        <div className="card-body py-4">
          <div className="form-group row my-2">
            <label className="col-4 col-form-label">Title:</label>
            <div className="col-8">
              <span className="form-control-plaintext font-weight-bolder">
                {props.jobWalkThroughItem?.title}
              </span>
            </div>
          </div>
          <div className="form-group row my-2">
            <label className="col-4 col-form-label">Date and time:</label>
            <div className="col-8">
              <span className="form-control-plaintext font-weight-bolder">
                {parseFromFirebaseDate(
                  props.jobWalkThroughItem?.createdAt,
                  "MM/DD/YY, hh:mma"
                ) ?? ""}
              </span>
            </div>
          </div>
          <div className="form-group row my-2">
            <label className="col-4 col-form-label">Attendees:</label>
            <div className="col-8">
              {props.jobWalkThroughItem?.attendees?.map((attendee) => {
                return (
                  <span className="form-control-plaintext font-weight-bolder">
                    {attendee.name}
                    {attendee.company ? `(${attendee.company})` : ""}
                  </span>
                );
              })}
            </div>
          </div>
          <div className="form-group row my-2">
            <label className="col-4 col-form-label">Notes:</label>
            <div className="col-8">
              <span
                className="form-control-plaintext font-weight-bolder"
                style={{ whiteSpace: "pre-wrap" }}
              >
                {props.jobWalkThroughItem?.notes}
              </span>
            </div>
          </div>

          {files && (
            <>
              <div className="form-group row my-2">
                <div className="col-9">
                  <MultipleItems
                    slidesToShow={9}
                    laptop={7}
                    tablet={5}
                    mobile={2}
                    images={files?.map((walkThroughImage) => {
                      return {
                        image: walkThroughImage.url,
                        title: walkThroughImage.fileName,
                        name: walkThroughImage.name,
                        createdAt: moment(
                          walkThroughImage.createdAt?.toDate()
                        ).format("MM/DD/YY, hh:mma"),
                        uploadedBy: walkThroughImage.createdBy,
                      };
                    })}
                    selectedImageIndex={formState?.selectedImageIndex ?? 0}
                    setSelectedImage={(selectedImage: any) => {
                      setFormState((previousState) => {
                        return {
                          ...previousState,
                          selectedImageIndex: selectedImage,
                        };
                      });
                    }}
                  />
                  <div
                    className="bgi-no-repeat bgi-size-cover rounded border min-h-265px image-input-wrapper "
                    onClick={() => {}}
                    style={{
                      backgroundImage: `url(${
                        files[formState?.selectedImageIndex ?? 0]?.url
                      })`,
                      backgroundSize: "contain, cover",
                      backgroundPosition: "center",
                    }}
                  />
                  <div className="mt-5"></div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default JobWalkThroughDetails;
