import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  TaskEntity,
  TaskStatus,
  TaskStatusType,
  TaskType,
  getTaskProductDescription,
  showCustomFields,
  showFootage,
  showGroundConditions,
  showProduct,
  showQuantity,
  showYards,
} from "../../../../shared/data/taskmanagement/Task";
import GoogleMapReact from "google-map-react";
import EQMarker from "../../../../components/GoogleMap/EQMarker";
import { CrewEntity, CrewMemberEntity } from "../../../../shared/data/Crew";
import { EquipmentEntity } from "../../../../shared/data/equipment/Equipment";
import { getCrewmembers, getCrews, getEquipment } from "../../../../shared/Api";
import { AuthContext } from "../../../../shared/Auth";
import {
  deleteTask,
  deleteTimeLog,
  getTask,
  getTaskTimeLogs,
  saveTask,
} from "../../../../shared/api/TaskManagerApi";
import { buildTimeLogFileEntityPath, buildTImeLogFilesPath, TimeLogEntity } from "../../../../shared/data/taskmanagement/TimeLog";
import TimeLogItem from "../Timelog/TimeLogItem";
import { Link, useHistory } from "react-router-dom";
import { parseFromFirebaseDate } from "../../../../shared/Util";
import EQExportReportComponent, {
  ExportReportProps,
} from "../../../../components/Form/EQExportReportComponent";
import { LabelKeyObject } from "react-csv/lib/core";
import TaskStatusComponent from "./TaskStatusComponent";
import ConfirmModal from "../../../../components/Modals/ConfirmModal";
import { ContextEntity } from "../../../../shared/data/context/ContextEntity";
import { db, storage } from "../../../../firebase";
import { FileEntity, toFileEntityDto } from "../../../../shared/data/folder/FolderStructure";
import { getFileData } from "../../../../shared/api/FilesApi";

export const TaskDetailsItem = ({ title = "", value = "" }) => {
  return (
    <div className="d-flex justify-content-between mb-2">
      <span className="text-dark-75 font-weight-bolder mr-2">{title}</span>
      <span className="text-muted">{value}</span>
    </div>
  );
};

interface TaskDetailsProps {
  task: TaskEntity;
  crews?: CrewEntity[];
  crewmembers?: CrewMemberEntity[];
  equipments?: EquipmentEntity[];
  timeLogs?: TimeLogEntity[];
}

export const TaskDetails = ({ task }: TaskDetailsProps) => {
  const authContext: ContextEntity = useContext(AuthContext);
  const userId = authContext.currentUser?.uid;
  const history = useHistory();
  const [taskData, setTaskData] = useState<TaskDetailsProps>({ task: task });

  const [reportState, setReportState] = useState<ExportReportProps>({
    reportTypes: ["Excel"],
  });

  const [dialogsState, setDialogsState] = useState({
    showDeleteTaskDialog: false,
  });

  const onTimeLogDeleted = useCallback(async (timeLogId: string) => {
    setTaskData((previousValue) => {
      const timeLogsAfterDeleting = previousValue?.timeLogs?.filter(
        (timeLog) => timeLog.id != timeLogId
      );
      return { ...previousValue, timeLogs: timeLogsAfterDeleting };
    });
    await deleteTimeLog(authContext?.currentUser?.company?.inventoryID ?? "", timeLogId)
    fetchData();
  }, []);

  const fetchData = async () => {
    const user = authContext.currentUser;
    const crews: any = await getCrews(user?.company?.inventoryID);
    const crewMembers: any = await getCrewmembers(
      user?.company?.inventoryID,
      user?.userProfile?.companyID
    );
    const equipments: any = await getEquipment(
      user?.company?.inventoryID,
      user?.userProfile?.companyID
    );

    const timeLogs = await getTaskTimeLogs(
      authContext.currentUser?.company?.inventoryID ?? "",
      task.id ?? ""
    );

    updateTimeLogsWithNewFileSystem(
      timeLogs.data ?? [],
      authContext.currentUser?.company?.inventoryID ?? "",
      authContext.currentUser?.userProfile?.companyID ?? ""
    );

    const taskDetails = await getTask(
      user?.company?.inventoryID ?? "",
      task?.id ?? ""
    );

    const taskCrews = crews?.data.filter((crew: any) => {
      return task.crews?.some((crewId) => crewId == crew.id);
    });

    const taskCrewmembers = crewMembers?.data.filter((crewMembers: any) => {
      return task.crewmembers?.some(
        (crewMemberId) => crewMemberId == crewMembers.id
      );
    });

    const taskEquipments = equipments?.data.filter((equipment: any) => {
      return task.equipments?.some(
        (equipmentId) => equipmentId == equipment.id
      );
    });

    const taskDetailsData = taskDetails?.data ?? task;

    const timeLogsData = timeLogs?.data?.map((timeLog) =>
      TimeLogEntity.buildTimeLog(timeLog, task, userId)
    );

    setTaskData((previousValue) => {
      return {
        ...previousValue,
        task: taskDetailsData,
        crews: taskCrews,
        crewmembers: taskCrewmembers,
        equipments: taskEquipments,
        timeLogs: timeLogsData,
      };
    });

    buildReport(taskDetailsData, timeLogsData ?? []);
  };

  //This method is used for updating bore logs to new file system. It should be deleted after 6 months of implementations considering that there will be no need for this since all bore logs are already updated with new file system.
  const updateTimeLogsWithNewFileSystem = async (
    timeLogs: TimeLogEntity[],
    inventoryId: string,
    companyId: string
  ) => {

    await Promise.all(
      timeLogs?.map(async (timeLog) => {
        const photos = [
          ...(timeLog.photos ?? []),
          ...(timeLog.imageTimeStamp ? [timeLog.imageTimeStamp] : []),
        ];

        const timeLogFiles = await getFileData(
          buildTimeLogFileEntityPath(inventoryId, timeLog.id ?? ""),
          buildTImeLogFilesPath(companyId, timeLog.id ?? "")
        );

        //If new file structure is not created continue with creating it
        if ((timeLogFiles.data ?? []).length < 1) {
          await Promise.all(
            photos?.map(async (photo) => {
              if (photos) {
                try {
                  const storageRef = storage.ref(
                    "/" +
                      companyId +
                      "/timeLog/" +
                      timeLog.id +
                      "/" +
                      photo.seconds +
                      "/"
                  );
                  const url = await storageRef.child("med").getDownloadURL();

                  const fileEntity: FileEntity = {
                    id: null,
                    name: null,
                    fileName: null,
                    createdBy: null,
                    fileType: "image",
                    createdAt: photo,
                    lat: timeLog?.lat ?? null,
                    lng: timeLog?.lng ?? null,
                    url: url ?? null,
                  };

                  const fileEntityPath = buildTimeLogFileEntityPath(
                    inventoryId,
                    timeLog.id ?? ""
                  );

                  const result = await db
                    .collection(fileEntityPath)
                    .add(toFileEntityDto(fileEntity));
                } catch (error) {
                  console.error(error);
                }
              }
            })
          );
        }
      })
    );
  };

  const buildReport = (task: TaskEntity, timeLogs: TimeLogEntity[]) => {
    const reportHeaders: LabelKeyObject[] = [
      { label: "Task ID", key: "taskId" },
      { label: "Task Title", key: "taskTitle" },
      { label: "Task Type", key: "taskType" },
      { label: "Task Start Date", key: "taskStartDate" },
      { label: "Task End Date", key: "taskEndDate" },
      { label: "Estimated Duration(h)", key: "estimatedDuration" },
      { label: "Current Duration(h)", key: "currentDuration" },
      { label: "Estimated Quantity", key: "estimatedQuantity" },
      { label: "Current Quantity", key: "currentQuantity" },
      { label: "Estimated Filled Yards", key: "expectedFilledYards" },
      { label: "Current Filled Yards", key: "currentFilledYards" },
      { label: "Estimated Footage", key: "expectedFootage" },
      { label: "Current Footage", key: "currentFootage" },
      { label: "Log Start Time", key: "startDate" },
      { label: "Log Spent Time", key: "spentTime" },
      { label: "Log Notes", key: "notes" },
      { label: "Log Latitude", key: "lat" },
      { label: "Log Longitude", key: "lng" },
      { label: "Log Quantity", key: "quantity" },
      { label: "Log Yards", key: "yards" },
      { label: "Log Footage", key: "footage" },
    ];
    const reportData = timeLogs.map((timeLog) => {
      return {
        taskId: task.taskID,
        taskTitle: task.title,
        taskType: task.taskType,
        taskStartDate: parseFromFirebaseDate(
          task?.startDate,
          "DD/MM/YY hh:mm A"
        ),
        taskEndDate: parseFromFirebaseDate(task?.endDate, "DD/MM/YY hh:mm A"),
        estimatedDuration: task.estimatedDuration,
        currentDuration: task.currentDuration,
        estimatedQuantity: task?.expectedQuantity,
        currentQuantity: task?.currentQuantity,
        expectedFilledYards: task?.expectedFilledYards,
        currentFilledYards: task?.currentFilledYards,
        expectedFootage: task?.expectedFootage,
        currentFootage: task?.currentFootage,
        startDate: parseFromFirebaseDate(timeLog.startDate, "DD/MM/YY hh:mm A"),
        spentTime: timeLog.duration,
        notes: timeLog.notes,
        lat: timeLog.lat,
        lng: timeLog.lng,
        quantity: timeLog.quantity,
        yard: timeLog.yards,
        footage: timeLog.footage,
      };
    });
    setReportState((previousState) => {
      return {
        ...previousState,
        data: reportData,
        header: reportHeaders,
        title: `report-task-${task.taskID}`,
      };
    });
  };

  useEffect(() => {
    fetchData().catch((error) => {});
  }, []);

  const changeTaskState = async (
    task: TaskEntity,
    taskStatus: TaskStatusType
  ) => {
    const inventoryId = authContext?.currentUser?.company?.inventoryID ?? "";
    const result = await saveTask(inventoryId, {
      ...task,
      taskStatus: taskStatus,
    });
    if (result?.data) {
      setTaskData((previousState) => {
        return { ...previousState, task: result.data!! };
      });
    }
  };

  const getTaskName = () => {
    var title = "Task Name";

    if (taskData.task.taskType == TaskType.borePilot.toString()) {
      title = "Bore Name";
      return <TaskDetailsItem title={title} value={`${taskData.task.title}`} />;
    }
    if (
      !Object.values(TaskType).find(
        (type) => type.toString() == taskData.task.taskType
      )
    ) {
      title = "Custom Task Name";
      return <TaskDetailsItem title={title} value={`${taskData.task.title}`} />;
    }
  };

  return (
    <React.Fragment>
      <div className="card card-custom p-6">
        <div className="card-header flex-wrap p-0">
          <div className="card-title">
            <h3 className="card-label">
              Task details
              <span className="d-block text-muted font-size-sm"></span>
            </h3>
          </div>
          <div className="card-toolbar">
            <EQExportReportComponent {...reportState} />
            {task?.id && (
              <div>
                <Link
                  className="btn btn-primary font-weight-bolder ml-2 float-right"
                  to={{
                    pathname: `/jobs/${taskData?.task?.jobID}/tasks/timelog`,
                    state: {
                      timeLog: TimeLogEntity.buildTimeLog(
                        new TimeLogEntity(task.jobID, userId ?? "", task.id),
                        task
                      ),
                      task: taskData.task,
                    },
                  }}
                  href="#"
                >
                  New time log
                </Link>
              </div>
            )}
            <TaskStatusComponent
              task={taskData.task}
              onDelete={async (task) => {
                if (task.id)
                  setDialogsState((previousState) => {
                    return { ...previousState, showDeleteTaskDialog: true };
                  });
              }}
              onComplete={(task) => {
                changeTaskState(task, "closed");
              }}
              onInProgress={(task) => {
                changeTaskState(task, "inProgress");
              }}
              onOpen={(task) => {
                changeTaskState(task, "open");
              }}
              onHold={(task) => {
                changeTaskState(task, "onHold");
              }}
              onEdit={(task) => {
                history.push({
                  pathname: `/jobs/${taskData?.task?.jobID}/taskmanagement/task`,
                  state: {
                    task: taskData.task,
                  },
                });
              }}
            />
          </div>
        </div>
        <div style={{ height: "40vh", width: "100%" }}>
          <GoogleMapReact
            options={{
              mapTypeControl: true,
              mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
              },
              mapTypeId: "satellite",
            }}
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAP_KEY ?? "",
            }}
            defaultZoom={14}
            defaultCenter={{
              lat: task?.lat ?? 0.0,
              lng: task?.lng ?? 0.0,
            }}
            center={{
              lat: task?.lat ?? 0.0,
              lng: task?.lng ?? 0.0,
            }}
          >
            <EQMarker
              key={1}
              lat={task?.lat}
              lng={task?.lng}
              pinColor={"darkgreen"}
            />
          </GoogleMapReact>
        </div>
        <div className="card mb-6 mt-6" key="task-details-container">
          <div className="mt-2 p-5" key="task-details-container-fields">
            <TaskDetailsItem
              title="Task ID"
              value={`#${taskData?.task?.taskID}`}
            />
            {getTaskName()}
            <TaskDetailsItem
              title="Task Type"
              value={`${taskData.task.taskType}`}
            />
            <TaskDetailsItem
              title="Task Details"
              value={`${taskData.task.notes ?? ""}`}
            />
            <TaskDetailsItem
              title="Estimated start date"
              value={`${parseFromFirebaseDate(taskData.task.startDate)}`}
            />
            <TaskDetailsItem
              title="Estimated end date"
              value={`${parseFromFirebaseDate(taskData.task.endDate) ?? ""}`}
            />
            <TaskDetailsItem
              title="Estimate Duration"
              value={`${taskData.task?.estimatedDuration?.toFixed(1) ?? 0.0}h`}
            />

            <TaskDetailsItem
              title="Current Duration"
              value={`${taskData.task.currentDuration?.toFixed(1) ?? 0.0}h`}
            />
            {showFootage(taskData.task) && (
              <div>
                <TaskDetailsItem
                  title="Estimate Footage"
                  value={`${taskData.task.expectedFootage?.toFixed(1) ?? 0.0}`}
                />
                <TaskDetailsItem
                  title="Current Footage"
                  value={`${taskData.task.currentFootage?.toFixed(1) ?? 0.0}`}
                />
              </div>
            )}
            {showYards(taskData.task) && (
              <div>
                <TaskDetailsItem
                  title="Estimate Yards"
                  value={`${
                    taskData.task.expectedFilledYards?.toFixed(1) ?? 0.0
                  }`}
                />
                <TaskDetailsItem
                  title="Current Yards"
                  value={`${
                    taskData.task.currentFilledYards?.toFixed(1) ?? 0.0
                  }`}
                />
              </div>
            )}
            {showQuantity(taskData.task) && (
              <div>
                <TaskDetailsItem
                  title="Current quantity"
                  value={`${taskData.task.currentQuantity?.toFixed(1) ?? 0.0}`}
                />
                <TaskDetailsItem
                  title="Estimate Quantity"
                  value={`${taskData.task.expectedQuantity?.toFixed(1) ?? 0.0}`}
                />
              </div>
            )}
            <TaskDetailsItem
              title="Task Status"
              value={`${TaskStatus[taskData.task.taskStatus ?? "open"]}`}
            />
            <TaskDetailsItem
              title="Task Priority"
              value={`${taskData.task.priority}`}
            />
            {showGroundConditions(taskData.task) && (
              <TaskDetailsItem
                title="Ground Conditions"
                value={`${task.groundConditions ?? ""}`}
              />
            )}
            {showProduct(taskData.task) &&
              taskData.task?.products?.map((product, index) => {
                return (
                  <TaskDetailsItem
                    title={`Product #${index + 1}`}
                    value={getTaskProductDescription(product)}
                  />
                );
              })}
            {showCustomFields(taskData.task) && (
              <div>
                <div className="separator separator-solid separator-dark my-1"></div>
                {taskData.task.customFields?.map((customField) => {
                  return (
                    <TaskDetailsItem
                      title={customField.title}
                      value={customField.value}
                    />
                  );
                })}
                <div className="separator separator-solid separator-dark my-1"></div>
              </div>
            )}
            <TaskDetailsItem
              title="Crews"
              value={
                taskData?.crews
                  ?.map((crew) => ` ${crew.crewName}`)
                  .toString() ?? ""
              }
            />
            <TaskDetailsItem
              title="Crewmembers"
              value={
                taskData?.crewmembers
                  ?.map(
                    (crewmember) =>
                      ` ${crewmember.firstName} ${crewmember.lastName}`
                  )
                  .toString() ?? ""
              }
            />
            <TaskDetailsItem
              title="Equipment"
              value={
                taskData?.equipments
                  ?.map((equipment) => ` ${equipment.make} ${equipment.model}`)
                  .toString() ?? ""
              }
            />
            <TaskDetailsItem
              title="Location"
              value={`${taskData.task.address ?? ""} - ${
                taskData.task.lat ?? 0
              },${taskData.task.lng ?? 0}`}
            />
          </div>
        </div>
        <div>
          <div className="d-flex flex-row mb-6 align-items-center">
            <div className="font-weight-bold flex-grow-1">TIME LOGS</div>
          </div>
          {taskData?.timeLogs?.map((timelog) => {
            return (
              <TimeLogItem
                timeLog={timelog}
                task={taskData.task}
                onDelete={onTimeLogDeleted}
              />
            );
          })}
        </div>
      </div>
      <ConfirmModal
        show={dialogsState.showDeleteTaskDialog}
        title="Delete task"
        body="Are you sure you want to delete this task?"
        yesButton={{
          title: "Yes",
          onClick: async () => {
            if (taskData.task.id) {
              await deleteTask(
                authContext?.currentUser?.company?.inventoryID ?? "",
                taskData.task.id
              );
              history.goBack();
              setDialogsState((previousState) => {
                return { ...previousState, showDeleteTaskDialog: false };
              });
            }
          },
        }}
        noButton={{
          title: "Cancel",
          onClick: () => {
            setDialogsState((previousState) => {
              return { ...previousState, showDeleteTaskDialog: false };
            });
          },
        }}
      />
    </React.Fragment>
  );
};

export default TaskDetails;
