import React from "react";
import { JobWalkThroughEntity } from "../../../shared/data/job/JobWalkThrough";
import { parseFromFirebaseDate } from "../../../shared/Util";
import { Link } from "react-router-dom";

interface JobWalkThroughItemProp {
  jobWalkThroughItem: JobWalkThroughEntity;
  jobID: string;
  onItemClick: (item: JobWalkThroughEntity) => void;
}

export const JobWalkThroughItem = (props: JobWalkThroughItemProp) => {
  return (
    <React.Fragment>
      <div className="card card-custom d-flex p-4 m-2">
        <div className="d-flex d-flex-row align-items-center mb-1">
          <Link
            to={{
              pathname: `/jobs/${props.jobID}/jobwalkthrough/details`,
              state: {
                jobWalkThroughItem: props.jobWalkThroughItem,
                jobId: props.jobID,
                jobWalkThroughId: props.jobWalkThroughItem.id ?? "",
              },
            }}
            href="#"
            className="flex-grow-1"
          >
            <div className="flex-grow-1">
              <h6 className="font-weight-bold text-dark">
                {props.jobWalkThroughItem.title}
              </h6>
              <h6 className="text-muted font-weight-bold">
                {parseFromFirebaseDate(
                  props.jobWalkThroughItem.createdAt,
                  "MM/DD/YY, hh:mma"
                ) ?? ""}
              </h6>
            </div>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

export default JobWalkThroughItem;
