import React, { useEffect, useRef, useState } from "react";
import WorkTimeEntity from "../../../shared/data/job/Job";
import SignatureCanvas from "react-signature-canvas";

interface DailyCheckInWorkTimeComponentProps {
  workTime: WorkTimeEntity;
  onSaveChanges?: (
    data?: WorkTimeEntity | null,
    saveCrewMember?: boolean
  ) => void;
}

const DailyCheckInWorkTimeComponent = ({
  workTime,
  onSaveChanges = () => {},
}: DailyCheckInWorkTimeComponentProps) => {
  const [workTimeState, setWorkTimeState] = useState<WorkTimeEntity>(workTime);

  const signatureCanvasRef = useRef<SignatureCanvas>(null);

  useEffect(() => {
    setWorkTimeState(workTime);
  }, [workTime]);

  useEffect(() => {
    signatureCanvasRef.current?.clear();
    signatureCanvasRef.current?.fromDataURL(
      `data:image/png;base64,${workTimeState.signature ?? ""}`, {ratio:0.5, width: 400, height: 80 }
    );
  }, [workTimeState.signature, workTimeState.email]);

  const getTitle = () => {
    if (workTime.firstName && workTime.lastName)
      return "Update crewmember work times";
    else return "Add new crewmember time";
  };

  const shouldSaveCrewMember = () => {
    if (workTime.firstName && workTime.lastName) return false;
    else return true;
  };

  const isDataValid = () => {
    if (
      workTimeState.firstName &&
      workTimeState.lastName &&
      isTravelTimeValid()
    )
      return false;
    else return true;
  };

  const isEmailValid = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const isTravelTimeValid = () => {
    return /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$|^0$/.test(
      workTimeState.travelTime
    );
  };

  return (
    <React.Fragment>
      <div>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {getTitle()}
              </h5>
            </div>
            <div className="modal-body">
              <div>
                <label className="mr-10">First name</label>
                <input
                  className="mr-10 form-control"
                  type="text"
                  value={workTimeState.firstName ?? "Undefined"}
                  disabled={workTime.firstName != ""}
                  onChange={(input) => {
                    setWorkTimeState((previousState) => {
                      return {
                        ...previousState,
                        firstName: input.target.value,
                      };
                    });
                  }}
                />
              </div>
              <div>
                <label className="mt-5 mr-10">Last name</label>
                <input
                  className="mr-10 form-control"
                  type="text"
                  value={workTimeState.lastName ?? "Undefined"}
                  disabled={workTime.lastName != ""}
                  onChange={(input) => {
                    setWorkTimeState((previousState) => {
                      return { ...previousState, lastName: input.target.value };
                    });
                  }}
                />
              </div>
              <div>
                <label className="mt-5 mr-10">Email</label>
                <input
                  className="mr-10 form-control"
                  type="text"
                  value={workTimeState.email ?? ""}
                  disabled={workTime.email != null}
                  onChange={(input) => {
                    setWorkTimeState((previousState) => {
                      return { ...previousState, email: input.target.value };
                    });
                  }}
                />
              </div>
              <div>
                <label className="mt-5 mr-10">Phone number</label>
                <input
                  className="mr-10 form-control"
                  type="text"
                  value={workTimeState?.phoneNumber ?? ""}
                  disabled={workTime.phoneNumber != null}
                  onChange={(input) => {
                    setWorkTimeState((previousState) => {
                      return {
                        ...previousState,
                        phoneNumber: input.target.value,
                      };
                    });
                  }}
                />
              </div>
              <div>
                <label className="mt-5 mr-10">Arrive Time</label>
                <input
                  disabled={workTimeState.notOnJob ?? false}
                  className="mr-10 form-control"
                  type="time"
                  value={workTimeState?.startTime ?? undefined}
                  onChange={(input) => {
                    setWorkTimeState({
                      ...workTimeState,
                      startTime: input.target.value,
                    });
                  }}
                />
              </div>
              <div>
                <label className="mt-5 mr-10">Leave Time</label>
                <input
                  disabled={workTimeState.notOnJob ?? false}
                  className="mr-10 form-control"
                  type="time"
                  value={workTimeState?.endTime ?? undefined}
                  onChange={(input) => {
                    setWorkTimeState({
                      ...workTimeState,
                      endTime: input.target.value,
                    });
                  }}
                />
              </div>
              <div>
                <label className="mt-5 mr-10">Travel Time</label>
                <input
                  disabled={workTimeState.notOnJob ?? false}
                  className={`mr-10 form-control ${
                    !isTravelTimeValid() ? "bg-danger" : ""
                  }`}
                  type="text"
                  value={workTimeState?.travelTime ?? ""}
                  onChange={(input) => {
                    setWorkTimeState((previousState) => {
                      return {
                        ...previousState,
                        travelTime: input.target.value,
                      };
                    });
                  }}
                />
                <div className="form-text text-muted">
                  Travel time in format of 02:30 or 0
                </div>
              </div>
              <div>
                <label className="mt-5">
                  Was a safety talk conducted at the start of the day?
                </label>
                <div className="col-9 col-form-label">
                  <div className="radio-inline">
                    <label className="radio radio-success">
                      <input
                        disabled={workTimeState.notOnJob ?? false}
                        type="radio"
                        checked={workTimeState.safetyTalk}
                        onChange={(event) => {
                          setWorkTimeState((previousState) => {
                            return { ...previousState, safetyTalk: true };
                          });
                        }}
                      />
                      <span></span>
                      YES
                    </label>
                    <label className="radio radio-success">
                      <input
                        disabled={workTimeState.notOnJob ?? false}
                        type="radio"
                        checked={!workTimeState.safetyTalk}
                        onChange={(event) => {
                          setWorkTimeState((previousState) => {
                            return { ...previousState, safetyTalk: false };
                          });
                        }}
                      />
                      <span></span>
                      NO
                    </label>
                  </div>
                </div>
              </div>
              <form className="form">
                <div className="form-group row">
                  <label className="col-9 col-form-label">
                    Crewmember was not on job
                  </label>
                  <div className="col-2">
                    <span className="switch">
                      <label>
                        <input
                          type="checkbox"
                          name="publicView"
                          checked={workTimeState.notOnJob ?? false}
                          onChange={(event) => {
                            const checked = event.target.checked;
                            setWorkTimeState((previousState) => {
                              return { ...previousState, notOnJob: checked };
                            });
                          }}
                        />
                        <span />
                      </label>
                    </span>
                  </div>
                </div>
              </form>
              {!workTimeState.notOnJob && (
                <div>
                  <label className="mt-5 mr-10">Crewmember signature:</label>
                  <label
                    className="text-dark font-weight-bold"
                    onClick={(event) => {
                      signatureCanvasRef.current?.clear();
                      setWorkTimeState((previousState) => {
                        return {
                          ...previousState,
                          signature: null,
                        };
                      });
                    }}
                  >
                    CLEAR
                  </label>
                  <div className="card card-body">
                    <SignatureCanvas
                      penColor="black"
                      canvasProps={{ width: 400, height: 80 }}
                      onEnd={(result) => {
                        // const signatureImage =
                        //   signatureCanvasRef.current
                        //     ?.getTrimmedCanvas()
                        //     ?.toDataURL("image/png")
                        //     ?.split(",")
                        //     .slice(1, 2)[0] ?? "";
                        // setWorkTimeState((previousState) => {
                        //   return {
                        //     ...previousState,
                        //     signature: signatureImage,
                        //   };
                        // });
                      }}
                      ref={signatureCanvasRef}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                disabled={isDataValid()}
                onClick={() => {
                  const workTime = {...workTimeState}
                  workTime.signature = signatureCanvasRef.current
                      ?.getTrimmedCanvas()
                      ?.toDataURL("image/png")
                      ?.split(",")
                      .slice(1, 2)[0] ?? "";
                  onSaveChanges(workTime, shouldSaveCrewMember());
                }}
                type="button"
                className={"btn btn-primary font-weight-bold"}
                data-dismiss="modal"
              >
                Save
              </button>
              <button
                onClick={() => {
                  onSaveChanges(null, false);
                }}
                type="button"
                className="btn btn-light-secondary font-weight-bold"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DailyCheckInWorkTimeComponent;
