import React, { useState } from "react";

interface EQLabelProps {
  title: string;
  label: string;
  numberOfLines?: number;
}

const EQLabel = (props: EQLabelProps) => {

  return (
    <React.Fragment>
      <div
        key={`eq-label-text-${props.title}}`}
        className="form-group"
      >
        <div className="font-weight-bold text-dark">{props.title}</div>
        {props.label && <div className="font-italic">{props.label}</div>}
      </div>
    </React.Fragment>
  );
};

export default EQLabel;
