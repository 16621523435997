import React, { Component } from "react";
import * as moment from "moment";
import $ from "jquery";
import { AuthContext } from "../../shared/Auth";
import app, { db, functions } from "../../firebase";
import { Link } from "react-router-dom";
import Loader from "../../components/Layout/Loader/Loader";
import {
  getClients,
  getContacts,
  getCrewAppointmentsByJob,
  getCrews,
  getCrewSchedules,
  updateJobDuration,
  updateUser,
} from "../../shared/Api";
import {
  downloadPDFTable,
  fromRGBValue,
  printPDFTable,
} from "../../shared/Util";
import RichDataTable from "../../components/Tables/RichDataTable";
import { ExportIcon, JobsIos } from "../../components/Icons/Svg";
import { CSVLink } from "react-csv";
import html2canvas from "html2canvas";
import Canvas2Image from "../../shared/Canvas2Image";
import InfoModal from "../../components/Modals/InfoModal";
import {getPlanTier } from "../../shared/data/context/UserEntity";
import SubscriptionRestrictionComponent from "../Profile/subscription/SubscriptionRestrictionComponent";
import { SubscriptionTierUI } from "../../shared/data/subscription/SubscriptionUI";

class Jobs extends Component {
  static contextType = AuthContext;
  user = this.context.currentUser.userProfile;
  isTutorial = this.context.isTutorial;
  tutorialStep = this.context.tutorialStep;
  subscriptionPlan = this.context.currentUser.subscriptionPlan;

  downloadCalendar = (fileName) => {
    html2canvas(document.getElementById("kt_calendar")).then(function (canvas) {
      return Canvas2Image.saveAsJPEG(canvas, 1200, 1200, fileName);
    });
  };

  getCalendarImage = (title, fileName, headers, data) => {
    html2canvas(document.getElementById("kt_calendar")).then(function (canvas) {
      let image;
      try {
        image = Canvas2Image.convertToJPEG(canvas, 1200, 800);
      } catch (error) {}
      downloadPDFTable(title, fileName, headers, data, image);
    });
  };

  state = {
    jobs: null,
    view: "month",
    clients: [],
    calendar: null,
    isCalendarDrawn: false,
    date: moment.now(),
    keyword: "",
    sortBy: "sort_by_date",
    filterBy: "",
    jobFields: [
      {
        display: "Job ID",
        name: "jobID",
      },
      {
        display: "Title",
        name: "title",
      },
      {
        display: "Schedule",
        name: "schedule",
      },
      {
        display: "Status",
        name: "status",
        customField: true,
        template: (job) => {
          return (
            '<span class="text-' +
            this.getClass(job.status) +
            '"' +
            'data-id="' +
            job.id +
            '">' +
            this.getJobStatusString(job.status) +
            "</span>"
          );
        },
      },
      {
        display: "Crews",
        name: "crews",
        customField: true,
        template: (job) => {
          return this.getCrewDots(job.crews);
        },
      },
    ],
    report: {
      startDate: null,
      endDate: null,
    },
    showWelcomeModal: false,
  };

  jobHeaders = () => {
    return [
      { label: "JOB ID#", key: "jobID" },
      { label: "JOB NAME", key: "title" },
      { label: "LOCATION", key: "locationName" },
      { label: "JOB INFO", key: "description" },
      { label: "DATES", key: "dates" },
      { label: "TOTAL FOOTAGE", key: "totalFootage" },
      { label: "CLIENT NAME AND CONTACT DETAILS", key: "clientsData" },
    ];
  };

  jobData = (handleCSVFormat = false) => {
    if (!this.state.jobs) return [];

    let filteredJobs = this.state.jobs;
    const report = this.state.report;

    if (
      report.startDate != null &&
      report.endDate != null &&
      this.state.view !== "list"
    ) {
      filteredJobs = this.state.jobs.filter((job) => {
        const startDate = moment(job.startDate.toDate()).isBetween(
          report.startDate,
          report.endDate,
          undefined,
          "[]"
        );
        const endDate = moment(job.endDate.toDate()).isBetween(
          report.startDate,
          report.endDate,
          undefined,
          "[]"
        );
        return startDate || endDate;
      });
    }

    return filteredJobs.map((job) => {
      let clientString = "";
      if (this.state.clients && job.clientID) {
        const client = this.state.clients.find(
          (client) => job.clientID === client.id
        );
        if (client && client.contacts) {
          client.contacts.data.forEach((contact) => {
            if (client.companyName) {
              clientString += client.companyName;
              clientString += "\r\n";
            }

            if (contact.firstName || contact.lastName) {
              clientString += contact.firstName + " " + contact.lastName;
              clientString += "\r\n";
            }

            if (contact.phoneNumber) {
              clientString += contact.phoneNumber;
              clientString += "\r\n";
            }

            if (contact.email) {
              clientString += contact.email;
              clientString += "\r\n";
            }

            if (contact.position) {
              clientString += contact.position;
              clientString += "\r\n";
            }

            clientString += "\r\n";
            clientString += "\r\n";
          });
        }
      }

      var result = null;
      if (handleCSVFormat) {
        result = {
          ...job,
          description: job.description.replaceAll('"', '""'),
          title: job.title.replaceAll('"', '""'),
          dates:
            moment(job.startDate.toDate()).format("MM/DD/YY") +
            "-" +
            moment(job.endDate.toDate()).format("MM/DD/YY"),
          clientsData: clientString,
        };
      } else {
        result = {
          ...job,
          dates:
            moment(job.startDate.toDate()).format("MM/DD/YY") +
            "-" +
            moment(job.endDate.toDate()).format("MM/DD/YY"),
          clientsData: clientString,
        };
      }
      return result;
    });
  };

  loadClients = async () => {
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const clients = await getClients(inventoryId);
    const data = clients.data;

    await Promise.all(
      data.map(async (cl) => {
        const contacts = await getContacts(inventoryId, cl.id);
        cl.contacts = contacts;
      })
    );

    this.setState({ clients: data });
  };

  getCrewDots = (crews) => {
    let cr = crews.slice(0, 5).map((crew, index) => {
      return (
        '<span class="dot table-dot" style= " background-color:rgba(' +
        fromRGBValue(crew.red) +
        ", " +
        fromRGBValue(crew.green) +
        ", " +
        fromRGBValue(crew.blue) +
        "," +
        crew.alpha +
        ')" ></span>'
      );
    });
    return cr.join("  ");
  };

  componentWillUnmount = () => {
    window.$('[data-toggle="tooltip"]').tooltip("hide");
    document.getElementById("skip-tutorial-button").style.display = "none";

    const calendarState = this.state.calendar.state
    const date = calendarState?.currentDate
    const view = this.state.view
    this.updateViewState(view, date)
  };

  componentDidMount() {
    const currentUser = this.context.currentUser;

    if (this.user.webTutorialPending) {
      const setIsTutorial = this.context.setIsTutorial;
      updateUser(currentUser.uid, { webTutorialPending: false });
      setIsTutorial(true);
    }

    this.getCrews();
    this.checkSubscription();
    this.loadViewState();

    this.context.updateBackButtonPoint(this.props.location.pathname);
  }

  handleTutorial() {
    if (this.isTutorial && this.tutorialStep === "welcomeMessage") {
      this.setState({ showWelcomeModal: true });
    }

    if (this.isTutorial && this.tutorialStep === "addJob") {
      this.showTutorialMessage();
    }
  }

  componentDidUpdate(previusProp, currentProp) {
    if (
      this.state.jobs &&
      this.state.jobs.length !== 0 &&
      !this.state.isCalendarDrawn
    ) {
      this.drawCalendar(this.state.jobs);
      this.setState({
        isCalendarDrawn: true,
      });
    }

    if (this.state.view !== "list" && currentProp.view === "list" && this.state.calendar) {
      this.state.calendar.updateSize(); // Force FullCalendar to recalculate sizes
    }
  }

  getCrews = async () => {
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    const crews = await getCrews(inventoryId);
    this.loadClients();

    db.collection("inventories")
      .doc(inventoryId)
      .collection("jobs")
      .get()
      .then(async (result) => {
        let data = result.docs.map((x) => {
          return { ...x.data(), id: x.id };
        });

        await Promise.all(
          data.map(async (element) => {
            const appoitments = await getCrewAppointmentsByJob(
              inventoryId,
              element.id
            );
            const appCrewIds = appoitments.data.map((y) => y.crewID);

            element.crews = crews.data.filter((x) => appCrewIds.includes(x.id));
          })
        );
        if (currentUser.userProfile.permissions === "crewmember") {
          const crewSchedules = await getCrewSchedules(inventoryId);
          data = data.filter((x) =>
            crewSchedules.data.some(
              (y) =>
                y.crewMemberID === currentUser.userProfile.crewmemberID &&
                x.id === y.jobID
            )
          );
        }

        this.setState({
          jobs: data.sort((a, b) => (a.startDate > b.startDate ? -1 : 1)),
        });
        this.handleTutorial();
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  };

  showTutorialMessage = () => {
    document.getElementById("black-layer-container").style.display = "block";
    document.getElementById("add-job-btn").style.zIndex = "1001";

    window.$('[data-toggle="tooltip"]').tooltip({ trigger: "manual" });
    window.$('[data-toggle="tooltip"]').tooltip("hide");
    window.$('[data-toggle="tooltip"]').tooltip("show");

    document.getElementById("skip-tutorial-button").style.display = "block";
  };

  checkSubscription = async () => {
    const updateMessageState = this.context.updateMessageState;

    var getRecurlyAccount = functions.httpsCallable("api/getRecurlyAccount");
    getRecurlyAccount()
      .then((result) => {
        const acc = result.data.account;
        const subscriptionActive =
          acc.state === "active" &&
          acc.has_active_subscription._ === "true" &&
          acc.has_past_due_invoice._ === "false";

        if (!subscriptionActive && this.user.permissions !== "crewmember") {
          app.auth().signOut();
          updateMessageState("subscription-error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  checkReg = (pathname, regex) => {
    const matcher = new RegExp(regex.replace(/:[^\s/]+/g, "([\\w-]+)"));
    return pathname.match(matcher);
  };

  loadViewState = () => {
      const view = this.context.appState?.job?.calendarView
      const date = this.context.appState?.job?.calendarDate
      this.setState({ view: view, date:date });
  };

  updateViewState = (view, date) => {
    this.context.setAppState({job: {calendarView: view, calendarDate : date}})
    this.setState({ view: view, date: date });
  };

  getClass = (status) => {
    switch (status) {
      case "published":
        return "success";
      case "draft":
        return "primary";
      default:
        return "warning";
    }
  };

  getEventBackgroundColor = (status) => {
    switch (status) {
      case "published":
        return "#1BC5BD";
      case "draft":
        return "#3699FF";
      default:
        return "#FFA800";
    }
  };

  drawCalendar = (jobs) => {
    const self = this;

    var todayDate = moment().startOf("day");
    var YM = todayDate.format("YYYY-MM");
    var YESTERDAY = todayDate.clone().subtract(1, "day").format("YYYY-MM-DD");
    var TODAY = todayDate.format("YYYY-MM-DD");
    var TOMORROW = todayDate.clone().add(1, "day").format("YYYY-MM-DD");

    var calendarEl = document.getElementById("kt_calendar");
    var calendar = new window.FullCalendar.Calendar(calendarEl, {
      plugins: ["bootstrap", "interaction", "dayGrid", "basicWeek", "list"],
      themeSystem: "bootstrap",

      //isRTL: KTUtil.isRTL(),

      header: {
        left: "prev,next today",
        center: "title",
      },

      height: 800,
      contentHeight: 780,
      aspectRatio: 3, // see: https://fullcalendar.io/docs/aspectRatio
      showNonCurrentDates: false,
      nowIndicator: true,
      now: TODAY,

      views: {
        dayGridMonth: { buttonText: "month" },
        dayGridWeek: { buttonText: "week" },
      },

      datesRender: function(info) {
        $('.fc-day.fc-today')
      },
      defaultView: this.state.view === "week" ? "dayGridWeek" : "dayGridMonth",
      defaultDate:
        this.state.date
          ? moment(this.state.date).format("YYYY-MM-DD")
          : TODAY,

      editable: true,
      eventLimit: false, // allow "more" link when too many events
      navLinks: true,
      eventDisplay: "block",
      eventColor: "#C9F7F5",
      eventResizableFromStart: true,
      events: jobs.map((job) => {
        const displayedEndDate = new Date(job.endDate.toDate());
        displayedEndDate.setDate(job.endDate.toDate().getDate() + 1);
        return {
          id: job.id,
          jobId: job.jobID,
          title: job.title + " - " + job.jobID,
          description: job.description,
          start: job.startDate.toDate(),
          end: displayedEndDate,
          backgroundColor: this.getEventBackgroundColor(job.status),
          borderColor: this.getEventBackgroundColor(job.status),
          className: "fc-event-" + this.getClass(job.status),
          status: job.status,
          crews: job.crews,
          allDay: true,
        };
      }),

      eventRender: function (info) {
        var element = $(info.el);
        if (info.event.extendedProps && info.event.extendedProps.description) {
          if (element.hasClass("fc-day-grid-event")) {
            element.data("content", info.event.extendedProps.description);
            element.data("placement", "top");
            //window.KTApp.initPopover(element);
          } else if (element.hasClass("fc-time-grid-event")) {
            element
              .find(".fc-title")
              .append(
                '<div class="fc-description">' +
                  info.event.extendedProps.description +
                  "</div>"
              );
          } else if (element.find(".fc-list-item-title").length !== 0) {
            element
              .find(".fc-list-item-title")
              .append(
                '<div class="fc-description">' +
                  info.event.extendedProps.description +
                  "</div>"
              );
          }
        }

        element
          .find(".fc-title")
          .append(
            "<span>    " +
              self.getCrewDots(info.event.extendedProps.crews) +
              "<span/>"
          );
      },
      datesRender: function (info) {
        self.setState((previousState) => {
          return {
            ...previousState,
            report: {
              startDate: info.view.currentStart,
              endDate: info.view.currentEnd,
            },
          };
        });
      },

      eventClick: function (info) {
        self.goToJob(null, info.event.id);
      },

      eventResize: function (info) {
        const event = info.event;
        const currentUser = self.context.currentUser;
        const inventoryId = currentUser.company.inventoryID;

        const endDate = new Date(event.end);
        endDate.setDate(event.end.getDate() - 1);
        updateJobDuration(inventoryId, event.id, event.start, endDate);
      },
    });

    this.setState({ calendar: calendar });
    try {
      calendar.render();
    } catch (err) {}
  };

  goTo = (view) => {
    switch (view) {
      case "list":
        break;
      case "month":
        this.state.calendar.changeView("dayGridMonth");
        break;
      case "week":
        this.state.calendar.changeView("dayGridWeek");
        break;
    }

    this.updateViewState(view, this.context.appState?.job?.calendarDate?? moment.now())
  };

  goToJob = (event, jobId) => {
    if (event) event.preventDefault();
    this.props.history.push("/jobs/" + jobId + "/details");
  };

  getListViewJobs = () => {
    let jobs = this.state.jobs;
    let keyword = this.state.keyword;
    let sortBy = this.state.sortBy;
    let filterBy = this.state.filterBy;

    jobs =
      keyword === ""
        ? jobs
        : jobs.filter(
            (job) =>
              job.title.toLowerCase().includes(keyword.toLowerCase()) ||
              job.jobID.toLowerCase().includes(keyword.toLowerCase())
          );

    jobs =
      filterBy === "" ? jobs : jobs.filter((job) => job.status == filterBy);

    switch (sortBy) {
      case "sort_by_date":
        jobs.sort((a, b) => {
          return b.startDate.toDate() - a.startDate.toDate();
        });
        break;
      case "sort_by_name":
        jobs.sort((a, b) => {
          return a.title > b.title ? 1 : b.title > a.title ? -1 : 0;
        });
        break;
      case "sort_by_job_id":
        jobs = jobs.sort((a, b) => {
          return a.jobID > b.jobID ? 1 : b.jobID > a.jobID ? -1 : 0;
        });
        break;
    }

    jobs.forEach((job) => {
      job.schedule =
        moment(job.startDate.toDate()).format("MMM DD, YYYY") +
        " - " +
        moment(job.endDate.toDate()).format("MMM DD, YYYY");
    });

    return jobs;
  };

  getJobStatusString = (status) => {
    if (status === "draft") {
      return "Bid";
    }

    if (status === "published") {
      return "Active Job";
    }

    if (status === "completed") {
      return "Completed Job";
    }

    return status;
  };

  render() {
    if (getPlanTier(this.context.currentUser.subscription?.plan?.plan_code) == SubscriptionTierUI.TIER1) {
      return (
        <SubscriptionRestrictionComponent permission={this.user.permissions} />
      );
    } else {
      if (!this.state.jobs) {
        return <Loader />;
      }
      if (this.state.jobs.length === 0) {
        return (
          <React.Fragment>
            <div className="d-flex align-items-center mb-10">
              <div className="d-flex flex-column flex-grow-1 font-weight-bold align-items-center">
                <div className="mainpage-graphic">
                  <JobsIos width={"200px"} height={"200px"} opacity={"0.3"} />
                </div>
                <span className="font-italic">
                  You don't have any jobs at the moment. You can start adding by
                  clicking the button below.
                </span>
                &nbsp;
                {this.user.permissions === "crewmember" ? null : (
                  <div className="card-toolbar">
                    <Link
                      to="/jobs/new"
                      id="add-job-btn"
                      className="btn btn-primary font-weight-bold"
                    >
                      <i className="ki ki-plus icon-md mr-2" />
                      Add Job
                    </Link>
                    &nbsp;
                  </div>
                )}
              </div>
            </div>
          </React.Fragment>
        );
      }

      const listViewJobs = this.getListViewJobs();

      return (
        <React.Fragment>
          <div className="card card-custom">
            <div className="card-header">
              <div className="card-title">
                <h3 className="card-label">Jobs</h3>
              </div>
              <div className="card-toolbar">
                <div className="dropdown dropdown-inline mr-2">
                  <button
                    type="button"
                    className="btn btn-light-primary font-weight-bolder dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span className="svg-icon svg-icon-md">
                      <ExportIcon />
                    </span>
                    Export
                  </button>
                  <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                    <ul className="navi flex-column navi-hover py-2">
                      <li className="navi-header font-weight-bolder text-uppercase font-size-sm text-primary pb-2">
                        Choose an option:
                      </li>
                      <li className="navi-item">
                        <a
                          role="button"
                          onClick={() => {
                            if (this.state.view == "list") {
                              printPDFTable(
                                "job-list.pdf",
                                this.jobHeaders(),
                                this.jobData()
                              );
                            } else {
                              this.downloadCalendar(
                                `Jobs-${moment(
                                  this.state.report.startDate
                                ).format("MMMM")}`
                              );
                            }
                          }}
                          className="navi-link"
                        >
                          <span className="navi-icon">
                            <i className="la la-print" />
                          </span>
                          <span className="navi-text">Print</span>
                        </a>
                      </li>

                      <li className="navi-item">
                        <CSVLink
                          filename={"job-list.csv"}
                          data={this.jobData(true)}
                          headers={this.jobHeaders()}
                          className="navi-link"
                        >
                          <span className="navi-icon">
                            <i className="la la-file-excel-o" />
                          </span>
                          <span className="navi-text">Excel</span>
                        </CSVLink>
                      </li>

                      <li className="navi-item">
                        <a
                          onClick={() => {
                            this.getCalendarImage(
                              "Jobs",
                              `Jobs-${moment(
                                this.state.report.startDate
                              ).format("MMMM")}`,
                              this.jobHeaders(),
                              this.jobData()
                            );
                          }}
                          role="button"
                          className="navi-link"
                        >
                          <span className="navi-icon">
                            <i className="la la-file-pdf-o" />
                          </span>
                          <span className="navi-text">PDF</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <a
                  onClick={() => this.goTo("month")}
                  href="#"
                  className={
                    this.state.view === "month"
                      ? "btn btn-icon btn-light-secondary mr-2 active"
                      : "btn btn-icon btn-light-secondary mr-2"
                  }
                >
                  <i className="flaticon2-calendar-2"></i>
                </a>
                <a
                  onClick={() => this.goTo("week")}
                  href="#"
                  className={
                    this.state.view === "week"
                      ? "btn btn-icon btn-light-secondary mr-2 active"
                      : "btn btn-icon btn-light-secondary mr-2"
                  }
                >
                  <i className="flaticon2-calendar-1"></i>
                </a>
                <a
                  onClick={() => this.goTo("list")}
                  href="#"
                  className={
                    this.state.view === "list"
                      ? "btn btn-icon btn-light-secondary mr-2 active"
                      : "btn btn-icon btn-light-secondary mr-2"
                  }
                >
                  <i className="flaticon-list-2"></i>
                </a>

                {this.user.permissions !== "crewmember" && (
                  <Link
                    onClick={() => {
                      if (this.isTutorial) {
                        window.$('[data-toggle="tooltip"]').tooltip("hide");
                        const setTutorialStep = this.context.setTutorialStep;
                        setTutorialStep("addingJob");
                        document.getElementById(
                          "black-layer-container"
                        ).style.display = "none";
                      }

                      this.props.history.push("/jobs/new");
                    }}
                    data-toggle="tooltip"
                    title="Let's Add a Money-Making Job"
                    id="add-job-btn"
                    className="btn btn-light-primary font-weight-bold"
                  >
                    <i className="ki ki-plus icon-md mr-2" />
                    Add Job
                  </Link>
                )}
              </div>
            </div>
            <div className="card-body">
              {this.state.view === "list" && (
                <div>
                  <div className="row align-items-start mb-12">
                    <div className="col-lg-4 my-2 my-md-0">
                      <div className="input-icon">
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.keyword}
                          onChange={(e) =>
                            this.setState({ keyword: e.target.value })
                          }
                          placeholder="Search..."
                          id="kt_jobs_list_search_query"
                        />
                        <span>
                          <i className="flaticon2-search-1 text-muted" />
                        </span>
                      </div>
                    </div>
                    <select
                      value={this.state.sortBy}
                      onChange={(e) =>
                        this.setState({ sortBy: e.target.value })
                      }
                      className="custom-select col-lg-4 my-2 my-md-0 ml-4"
                    >
                      <option value="sort_by_date">Sort by date</option>
                      <option value="sort_by_name">Sort by name</option>
                      <option value="sort_by_job_id">Sort by job ID</option>
                    </select>
                    <select
                      value={this.state.filterBy}
                      onChange={(e) =>
                        this.setState({ filterBy: e.target.value })
                      }
                      className="custom-select col-lg-2 my-2 my-md-0 ml-4"
                    >
                      <option value="" disabled selected>
                        Filter by
                      </option>
                      <option value="draft">Bid</option>
                      <option value="published">Active Job</option>
                      <option value="completed">Completed</option>
                    </select>
                    <button
                      onClick={() =>
                        this.setState({ filterBy: "", keyword: "" })
                      }
                      className="btn btn-primary font-weight-bold ml-4 w-10"
                    >
                      Clear
                    </button>
                  </div>
                  <div className="timeline timeline-5">
                    <>
                      <RichDataTable
                        title="Job list"
                        newRecord={{
                          title: "Add contact",
                          onClick: () => {
                            console.log("add contact");
                          },
                        }}
                        actions={{
                          delete: {
                            enabled: false,
                            onClick: (id) => {
                              console.log(id);
                            },
                          },
                          edit: {
                            enabled: false,
                            onClick: (id) => {
                              console.log(id);
                            },
                          },
                          detail: {
                            enabled: true,
                            onClick: (id) => {
                              this.props.history.push(
                                "/jobs/" + id + "/details"
                              );
                            },
                          },
                          duplicate: {
                            enabled: false,
                            onClick: (id) => {
                              console.log(id);
                            },
                          },
                        }}
                        fields={this.state.jobFields}
                        data={listViewJobs}
                        hideHeader={true}
                        hideImageColumn={true}
                        goToJob={this.goToJob}
                        removeShadow={true}
                        showExportButton={false}
                        waitForImages={false}
                      />
                    </>
                  </div>
                </div>
              )}
              <div
                style={{
                  display: this.state.view === "list" ? "none" : "block",
                }}
                id="kt_calendar"
              />
            </div>
          </div>
          <InfoModal
            show={this.state.showWelcomeModal}
            title="Welcome to equappment!"
            id="welcome-message"
            body="Watch this short message from Equappment founder Peter Melsheimer"
            videoUrl="https://firebasestorage.googleapis.com/v0/b/equappment.appspot.com/o/Video%2FEquappment_Welcome_Video.mp4?alt=media&token=b97dc8d7-5fb6-471d-a39d-148c32427e47"
            yesButton={{
              title: "Help me get started",
              onClick: () => {
                const nextTutorialStep = this.context.nextTutorialStep;
                nextTutorialStep();
                this.showTutorialMessage();
              },
            }}
          />
        </React.Fragment>
      );
    }
  }
}

export default Jobs;
