import { Timestamp } from "firebase/firestore"
import { FirebaseInventoriesCollections, FirebaseJobCollections, FirebaseRootCollections, FirebaseTimeLogCollections, FirebaseWalkThroughCollections } from "../../firebase/FirebaseSchema"

export interface JobWalkThroughEntity {
    id?: string | null
    userID?: string | null
    jobID?: string | null
    title?: string | null
    createdAt?: Timestamp | null
    notes?: string | null
    attendees?: JobWalkThroughAttendeeEntity[] | null
}

export interface JobWalkThroughAttendeeEntity {
    name?: string | null
    company?: string | null
}

export const buildJobWalkThroughFileEntityPath = (inventoryId: string, jobId:string, jobWalkThroughId:string) => {
    return `/${FirebaseRootCollections.INVENTORIES}/${inventoryId}/${FirebaseInventoriesCollections.JOBS}/${jobId}/${FirebaseJobCollections.WALK_THROUGH}/${jobWalkThroughId}/${FirebaseWalkThroughCollections.FILES}`
}

export const buildJobWalkThroughFileUploadPath = (companyId: string, jobId:string, jobWalkThroughId:string, folderName: string) => {
    // /3cff9090-d31f-11ea-b657-ff7ffa00cdac/job/BPn26VBJj4lgRkYTMgae/walkThrough/2y1wianXqDWgPo3rRdcg/images/1736073408
    return `${companyId}/job/${jobId}/walkThrough/${jobWalkThroughId}/images/${folderName}`
}

export const buildJobWalkThroughFilesPath = (companyId: string, jobId:string, jobWalkThroughId:string) => {
    // return `${companyId}/timeLog/${timeLogId}`
    return `${companyId}/job/${jobId}/walkThrough/${jobWalkThroughId}/images`
}