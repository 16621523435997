import React, { useEffect, useState } from "react";
import { JobWalkThroughEntity } from "../../../shared/data/job/JobWalkThrough";
import JobWalkThroughItem from "./JobWalkThroughItem";
import { JobEntity } from "../../../shared/data/job/Job";

interface JobWalkThroughListProps {
  job: JobEntity;
  jobWalkThroughList: JobWalkThroughEntity[];
}

export const JobWalkThroughList = (props: JobWalkThroughListProps) => {
  const [walkThroughList, setWalkThroughList] = useState<JobWalkThroughEntity[]>(
    props.jobWalkThroughList
  );

  useEffect(() => {
    setWalkThroughList(props.jobWalkThroughList);
  }, [[props.jobWalkThroughList]]);

  return (
    <React.Fragment>
      <div className="card card-custom card-shadowless rounded-top-0 bg-light">
        <div className="card-body p-0">
          <div className="row justify-content-center py-lg-10 px-lg-10">
            <div className="col-xl-12 col-xxl-12">
              {" "}
              {walkThroughList.map((item) => {
                return (
                  <JobWalkThroughItem
                    jobID={props?.job?.id ?? ""}
                    jobWalkThroughItem={item}
                    onItemClick={(item) => {}}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default JobWalkThroughList;
