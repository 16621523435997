import { Timestamp } from "firebase/firestore"
import { parseFromFirebaseDate } from "../Util"
import { ImageEntity } from "./taskmanagement/TimeLog"
import { storage } from "../../firebase"
import { FileEntity, FolderEntity, FileType as StructureFileType } from "./folder/FolderStructure"
import moment from "moment"
import { FirebaseLocateTicketCollection, FirebaseRootCollections } from "../firebase/FirebaseSchema"

export class LocateTicketEntity {
    id?: string | null = null
    jobID?: string | null = null
    ticketID?: string | null = null
    address?: string | null = null
    startDate?: Timestamp | null = null
    companyID?: string | null = null
    expirationDate?: Timestamp | null = null
    inventoryID?: string | null = null
    revisionNo?: string | null = null
    lat?: number | null = null
    lng?: number | null = null
    notes?: string | null = null
    utilityCompany?: string | null = null
    utilityCompanyAddress?: string | null = null
    status?: LocateTicketStatusEntity[] | null = []

    constructor(jobID: string | null = null, companyId: string | null = null, inventoryID: string | null = null, lat:number | null = null, lng:number|null=null) {
        this.jobID = jobID
        this.companyID = companyId
        this.inventoryID = inventoryID
        this.lat = lat
        this.lng = lng
    }
}

export function getLocateTicketCurrentStatus(locateTicket: LocateTicketEntity): LocateTicketStatusEntity | null {
    return locateTicket?.status?.filter(status => status != null).sort((a, b) => ((a.createdAt??Timestamp.now()) > (b.createdAt??Timestamp.now())) ? -1 : 1)[0]??null
}

export const buildLocateTicketFileEntityPath = (locateTicketId: string) => {
    return `/${FirebaseRootCollections.LOCATE_TICKETS}/${locateTicketId}/${FirebaseLocateTicketCollection.FILES}`
}

export const buildLocateTicketFileUploadPath = (companyId: string, locateTicketId: string, folderName: string) => {
    return `${companyId}/locateTicket/${locateTicketId}/${folderName}`
}

export const buildLocateTicketFilesPath = (companyId:string, locateTicketId: string) => {
    return `${companyId}/locateTicket/${locateTicketId}`
}

export enum LocateTicketStatus {
    readyToSubmit = "Ready to submit",
    submitted = "submitted",
    approved = "approved",
    expired = "expired",
}

export type LocateTicketStatusType = "Ready to submit" | "submitted" | "approved" | "expired"

export class LocateTicketStatusEntity {
    createdAt?: Timestamp | null = null
    date?: Timestamp | null = null
    type?: LocateTicketStatusType | null = null
    userID?: string | null = null
    userName?: string | null = null

    constructor(createdAt: Timestamp | null = null, date: Timestamp | null = null,
        type: LocateTicketStatusType | null = null, userId: string | null = null, userName: string | null = null) {
        this.createdAt = createdAt
        this.date = date
        this.type = type
        this.userID = userId
        this.userName = userName
    }
}

export type FileType = "document" | "image"

export class LocateTicketFileEntity {
    id?: string | null = null
    createdAt?: Timestamp | null = null
    createdBy?: string | null = null
    fileName?: string | null = null
    name?: string | null = null
    type?: FileType | null = null
    fileType?: FileType | null = null
    desc?: string | null = null
}

export const getLocateTicketFilesUrl = async (locateTicket: LocateTicketEntity, files: LocateTicketFileEntity[]) => {
    const result: ImageEntity[] = []

    await Promise.all(files.map(async (file) => {

        try {
            const fileName = file.type == "image" ? "med" : file.fileName

            const imageUrl = await storage
                .ref(`/${locateTicket.companyID}/locateTicket/${locateTicket.id}/${file.createdAt?.seconds}/${fileName}`)
                .getDownloadURL();

            result.push({
                file: file.fileName,
                value: file.createdAt,
                src: imageUrl
            })
        } catch (error: any) {
            console.log(error)
        }
    }))

    return result
}

export const buildLocateTicketFileStructure = async (locateTicket: LocateTicketEntity, files: LocateTicketFileEntity[]) => {
    var folder: FolderEntity | null = null
    const locateTicketFiles: FileEntity[] = []

    await Promise.all(files.map(async (file) => {
        try {
            const fileName = file.type == "image" ? "med" : file.fileName

            const fileUrl = await storage
                .ref(`/${locateTicket.companyID}/locateTicket/${locateTicket.id}/${file.createdAt?.seconds}/${fileName}`)
                .getDownloadURL();

            var fileType: StructureFileType
            if (file.type == "document" || file.fileType)
                fileType = "document"
            else fileType = "photo"

            locateTicketFiles.push({
                id: file.id ?? "",
                name: file.name ?? file.fileName ?? "<no-name>",
                fileName: file.fileName,
                readonly: true,
                createdAt: file.createdAt??Timestamp.now(),
                createdBy: file.createdBy,
                parentId: null,
                url: fileUrl,
                fileType: fileType
            });
        } catch (error: any) {

        }
    }))

    folder = {
        id: locateTicket.id ?? "",
        name: locateTicket.ticketID ?? "<no-name>",
        readonly: true,
        children: locateTicketFiles,
        parentId: null,
        createdAt: Timestamp.now(),
        createdBy: null,
        createdByID: null,
        description: ""
    }

    return folder
}