import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { EquipmentIos } from '../../components/Icons/Svg';
import Loader from '../../components/Layout/Loader/Loader';

class JobEquipment extends Component {
  state = {
    search: "",
  };

  componentDidMount() {
    this.props.setTabActive("equipment");
  }

  getAdded = () => {
    if (this.state.search === "") return this.props.addedEquipment;

    return this.props.addedEquipment.filter(
      (x) =>
        x.make.toLowerCase().includes(this.state.search.toLowerCase()) ||
        x.model.toLowerCase().includes(this.state.search.toLowerCase()) ||
        x.equipmentID.toString()?.toLowerCase().includes(this.state.search.toLowerCase())
    );
  };

  getOther = () => {
    if (this.state.search === "") return this.props.otherEquipment;

    return this.props.otherEquipment.filter(
      (otherEquipment) =>
        otherEquipment.make
          .toString()
          .toLowerCase()
          .includes(this.state.search.toLowerCase()) ||
        otherEquipment.model
          .toString()
          .toLowerCase()
          .includes(this.state.search.toLowerCase()) ||
        otherEquipment.equipmentID.toString()
          ?.toLowerCase()
          .includes(this.state.search.toLowerCase())
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-xl-12">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  this.setState({ search: e.target.value });
                }}
                placeholder="Search"
              />
            </div>
          </div>
          {/* <div className="col-xl-2">
                        <Link
                            //to={'/jobs/' + this.props.job.id + '/equipment/new'}
                            to="#"
                            className='btn btn-primary font-weight-bold mr-4 w-100'>Add new material</Link>
                    </div> */}
        </div>
        <div className="row">
          <div className="col-xl-6 pt-10 pt-xl-0">
            <div className="card card-custom card-stretch" id="kt_todo_view">
              <div className="card-header align-items-center flex-wrap justify-content-between border-0 py-6 h-auto">
                <div className="d-flex flex-column mr-2 py-2">
                  <a
                    href="#"
                    className="text-dark text-hover-primary font-weight-bold font-size-h4 mr-3"
                  >
                    Added equipment
                  </a>
                </div>
              </div>
              <div className="separator separator-dashed mt-3 mb-2"></div>
              <div className="card-body p-0">
                {this.props.equipmentListLoading ? (
                  <Loader height="100px" />
                ) : (
                  <div className="card-body pt-4 d-flex flex-column justify-content-between">
                    {this.getAdded().length > 0 ? (
                      <>
                        {this.getAdded().map((item, index) => {
                          return (
                            <Link
                              key={"jb-eq-gtadd" + index}
                              to={
                                "/jobs/" +
                                this.props.job.id +
                                "/equipment/" +
                                item.id
                              }
                            >
                              <div className="d-flex align-items-center mb-7">
                                <div className="flex-shrink-0 mr-4 mt-lg-0 mt-3">
                                  {item.image ? (
                                    <div className="symbol symbol-lg-85">
                                      <img alt="Pic" src={item.image} />
                                    </div>
                                  ) : (
                                    <div className="symbol symbol-lg-85 symbol-primary">
                                      <span className="symbol-label font-size-h3 font-weight-boldest">
                                        {item.name}
                                      </span>
                                    </div>
                                  )}
                                </div>
                                <div className="d-flex flex-column">
                                  <span className="text-muted font-weight-bold">
                                    ID: {item.equipmentID}
                                  </span>
                                  <span className="text-muted font-weight-bold">
                                    Make: {item.make}
                                  </span>
                                  <span className="text-muted font-weight-bold">
                                    Model: {item.model}
                                  </span>
                                  <span className="text-muted font-weight-bold">
                                    Year: {item.year}
                                  </span>
                                </div>
                              </div>
                            </Link>
                          );
                        })}
                      </>
                    ) : (
                      <div className="d-flex align-items-center mb-10">
                        <div className="d-flex flex-column flex-grow-1 font-weight-bold align-items-center">
                          <div className="mainpage-graphic">
                            <EquipmentIos
                              width={"200px"}
                              height={"200px"}
                              opacity={"0.3"}
                            />
                          </div>
                          <span className="font-italic">
                            You don't have any equipment added to the job at the
                            moment. You can start by clicking on a piece of
                            equipment from the list on the right and then
                            selecting 'Reserve'.
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-xl-6 pt-10 pt-xl-0">
            <div className="card card-custom card-stretch" id="kt_todo_view">
              <div className="card-header align-items-center flex-wrap justify-content-between border-0 py-6 h-auto">
                <div className="d-flex flex-column mr-2 py-2">
                  <a
                    href="#"
                    className="text-dark text-hover-primary font-weight-bold font-size-h4 mr-3"
                  >
                    Other equipment
                  </a>
                  <span className="font-italic">
                    Click on a equipment to schedule them to the job
                  </span>
                </div>
              </div>
              <div className="separator separator-dashed mt-3 mb-2"></div>
              <div className="card-body p-0">
                {this.props.equipmentListLoading ? (
                  <Loader height="100px" />
                ) : (
                  <div className="card-body pt-4 d-flex flex-column justify-content-between">
                    {this.getOther().length > 0 ? (
                      <>
                        {this.getOther().map((item, index) => {
                          return (
                            <Link
                              key={"jb-eq-gtoth" + index}
                              to={
                                "/jobs/" +
                                this.props.job.id +
                                "/equipment/" +
                                item.id
                              }
                            >
                              <div className="d-flex align-items-center mb-7">
                                <div className="flex-shrink-0 mr-4 mt-lg-0 mt-3">
                                  {item.image ? (
                                    <div className="symbol symbol-lg-85">
                                      <img alt="Pic" src={item.image} />
                                    </div>
                                  ) : (
                                    <div className="symbol symbol-lg-85 symbol-primary">
                                      <span className="symbol-label font-size-h3 font-weight-boldest">
                                        {item.name}
                                      </span>
                                    </div>
                                  )}
                                </div>
                                <div className="d-flex flex-column">
                                  <span className="text-muted font-weight-bold">
                                    ID: {item.equipmentID}
                                  </span>
                                  <span className="text-muted font-weight-bold">
                                    Make: {item.make}
                                  </span>
                                  <span className="text-muted font-weight-bold">
                                    Model: {item.model}
                                  </span>
                                  <span className="text-muted font-weight-bold">
                                    Year: {item.year}
                                  </span>
                                </div>
                              </div>
                            </Link>
                          );
                        })}
                      </>
                    ) : (
                      <div className="d-flex align-items-center mb-10">
                        <div className="d-flex flex-column flex-grow-1 font-weight-bold align-items-center">
                          <div className="mainpage-graphic">
                            <EquipmentIos
                              width={"200px"}
                              height={"200px"}
                              opacity={"0.3"}
                            />
                          </div>
                          <span className="font-italic">
                            There is no equipment for you to add to this job.
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default JobEquipment;