import { useContext } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import PrivateLayout from "./hoc/Layout/PrivateLayout";
import PublicLayout from "./hoc/Layout/PublicLayout";
import Loader from "./components/Layout/Loader/Loader";

import Signin from "./containers/Auth/Signin/Signin";
import Signup from "./containers/Auth/Signup/Signup";
import AcceptInvite from "./containers/Auth/AcceptInvite/AcceptInvite";
import ResetPassword from "./containers/Auth/ResetPassword/ResetPassword";
import WelcomeScreen from "./containers/Auth/WelcomeScreen/WelcomeScreen";
import Logout from "./containers/Auth/Logout/Logout";
import Landing from "./containers/Auth/Landing/LandingContent";

import Equipment from "./containers/Equipment/Equipment";
import EquipmentNew from "./containers/Equipment/EquipmentNew";
import EquipmentDetails from "./containers/Equipment/EquipmentDetails";
import EquipmentEdit from "./containers/Equipment/EquipmentEdit";
import ScheduleEquipment from "./containers/Equipment/ScheduleEquipment";
import ScheduleEquipmentDetails from "./containers/Equipment/ScheduleEquipmentDetails";
import ScheduleEquipmentEdit from "./containers/Equipment/ScheduleEquipmentEdit";

import Crewmember from "./containers/Crewmembers/Crewmember";
import CrewmemberNew from "./containers/Crewmembers/CrewmemberNew";
import CrewmemberEdit from "./containers/Crewmembers/CrewmemberEdit";
import CrewmemberDetails from "./containers/Crewmembers/CrewmemberDetails";

import Settings from "./containers/Settings/Settings";
import Me from "./containers/Profile/Me";

import Jobs from "./containers/Jobs/Jobs";
import JobNew from "./containers/Jobs/Create/JobNew";
import JobDetails from "./containers/Jobs/JobDetails";
import JobCrewDetails from "./containers/Jobs/JobCrewDetails";
import JobEquipmentDetails from "./containers/Jobs/JobEquipmentDetails";
import JobEquipmentNew from "./containers/Jobs/JobEquipmentNew";
import JobEquipmentCheckoutList from "./containers/Jobs/JobEquipmentCheckoutList";
import JobEquipmentCheckinList from "./containers/Jobs/JobEquipmentCheckinList";
import BoresDetails from "./containers/Jobs/Bores/BoresDetails";
import BoreLogs from "./containers/Jobs/Bores/BoreLogs";
import BoreLogsNew from "./containers/Jobs/Bores/BoreLogsNew";
import BoreLogsEdit from "./containers/Jobs/Bores/BoreLogsEdit";
import BoreLogsDetails from "./containers/Jobs/Bores/BoreLogsDetails";
import SegmentNew from "./containers/Jobs/TaskManagement/Segment/SegmentNew";

import Crews from "./containers/Crews/Crews";
import CrewsDetails from "./containers/Crews/CrewsDetails";
import CrewsNew from "./containers/Crews/CrewsNew";
import CrewsEdit from "./containers/Crews/CrewsEdit";

import { AuthContext } from "./shared/Auth";

import "./App.css";
import JoinCompany from "./containers/Auth/JoinCompany/JoinCompany";
import Client from "./containers/Clients/Client";
import ClientNew from "./containers/Clients/ClientNew";
import ClientEdit from "./containers/Clients/ClientEdit";
import ClientDetails from "./containers/Clients/ClientDetails";
import ContactNew from "./containers/Clients/ContactNew";
import ContactEdit from "./containers/Clients/ContactEdit";
import Calculators from "./containers/Calculator/Calculator";
import SoilConditions from "./containers/SoilConditions/SoilConditions";
import SoilConditionsNew from "./containers/SoilConditions/SoilConditionsNew";
import SoilConditionsDetails from "./containers/SoilConditions/SoilConditionsDetails";
import SoilConditionsEdit from "./containers/SoilConditions/SoilConditionsEdit";
import Notifications from "./containers/Notifications/Notifications";
import NotificationsEdit from "./containers/Notifications/NotificationsEdit";
import NotificationsNew from "./containers/Notifications/NotificationsNew";
import TaskNew from "./containers/Jobs/TaskManagement/Task/TaskNew";
import SegmentDetails from "./containers/Jobs/TaskManagement/Segment/SegmentDetails";
import TaskDetails from "./containers/Jobs/TaskManagement/Task/TaskDetails";
import TimeLogNew from "./containers/Jobs/TaskManagement/Timelog/TimeLogNew";
import DumpSite from "./containers/DumpSite/NewDumpSiteComponent";
import DumpSiteDetailsComponent from "./containers/DumpSite/DumpSiteDetailsComponent";
import DailyCheckIn from "./components/Reports/DailyProduction/DailyCheckIn";
import LocateTicketNew from "./containers/LocateTicket/new/LocateTicketNew";
import LocateTicketDetails from "./containers/LocateTicket/LocateTicketDetails";
import LandingPage from "./containers/Auth/Landing/LandingPage";
import BoresNew from "./containers/Jobs/Bores/BoresNew";
import LocateTicketContainer from "./containers/LocateTicket/LocateTicketContainer";
import JobWalkThroughDetails from "./containers/Jobs/JobWalkThrough/JobWalkThroughDetails";
import JobWalkThroughNew from "./containers/Jobs/JobWalkThrough/JobWalkThroughNew";

const App = () => {
  const {
    currentUser,
    messageState,
    showWelcomeScreen,
    isTutorial,
    tutorialStep,
  } = useContext(AuthContext);

  if (currentUser === "pending") {
    return <Loader />;
  }

  const renderLocateTicketDetails = (location) => {
    if (location.state) {
      return (
        <LocateTicketDetails
          locateTicket={location.state.locateTicket}
          job={location.state.job}
          jobs={location.state.jobs}
        />
      );
    }
  };

  const renderNewLocateTicket = (location) => {
    if (location.state) {
      return (
        <LocateTicketNew
          locateTicket={location.state.locateTicket}
          jobs={location.state.jobs}
        />
      );
    }
  };

  let routes = (
    <BrowserRouter>
      <PrivateLayout>
        <Switch>
          <Route path="/" exact component={Jobs} />
          <Route path="/equipment" exact component={Equipment} />
          <Route path="/equipment/new" exact component={EquipmentNew} />
          <Route path="/equipment/new/:id" exact component={EquipmentNew} />
          <Route path="/equipment/:id/edit" component={EquipmentEdit} />
          <Route path="/equipment/:id" exact component={EquipmentDetails} />
          <Route
            path="/equipment/:id/schedule/:scheduleId/edit"
            exact
            component={ScheduleEquipmentEdit}
          />
          <Route
            path="/equipment/:id/schedule/:scheduleId"
            exact
            component={ScheduleEquipmentDetails}
          />
          <Route
            path="/equipment/:id/schedule"
            exact
            component={ScheduleEquipment}
          />
          <Route path="/settings" component={Settings} />
          <Route path="/me" component={Me} />
          <Route path="/crewmembers" exact component={Crewmember} />
          <Route path="/crewmembers/new" exact component={CrewmemberNew} />
          <Route path="/crewmembers/:id" exact component={CrewmemberDetails} />
          <Route path="/crewmembers/:id/edit" component={CrewmemberEdit} />
          <Route path="/clients" exact component={Client} />
          <Route path="/clients/new" exact component={ClientNew} />
          <Route path="/clients/:id/edit" component={ClientEdit} />
          <Route path="/clients/:id" exact component={ClientDetails} />
          <Route
            path="/clients/:clientId/contacts/new"
            exact
            component={ContactNew}
          />
          <Route
            path="/clients/:clientId/contacts/:id/edit"
            exact
            component={ContactEdit}
          />
          <Route
            path="/jobs/:id/crewmembers/:crewmemberId"
            exact
            component={JobCrewDetails}
          />
          <Route
            path="/jobs/:id/equipment/:equipmentId"
            exact
            component={JobEquipmentDetails}
          />
          <Route
            path="/jobs/:id/custom_equipment/new"
            exact
            component={JobEquipmentNew}
          />
          <Route path="/jobs/:id/bores/new" exact component={BoresNew} />
          <Route
            path="/jobs/:id/segments/new"
            render={({ location }) => {
              if (location.state) {
                return <SegmentNew location={location} />;
              }
            }}
          />
          <Route
            path="/jobs/:id/segments/edit"
            render={({ location }) => {
              if (location.state) {
                return <SegmentNew location={location} />;
              }
            }}
          />
          <Route
            path="/jobs/:id/segments/:segmentId"
            render={({ location }) => {
              if (location.state) {
                return (
                  <SegmentDetails
                    segment={location.state.segment}
                    segmentTasks={location.state.segmentTasks}
                    job={location.state.job}
                  />
                );
              }
            }}
          />
          <Route
            path="/jobs/:id/taskmanagement/task"
            render={({ location }) => {
              if (location.state) {
                return (
                  <TaskNew
                    taskDateMin={location.state.taskDateMin}
                    taskDateMax={location.state.taskDateMax}
                    task={location.state.task}
                    segmentStartLocation={location.state.segmentStartLocation}
                    segmentEndLocation={location.state.segmentEndLocation}
                    segmentTasksLocations={location.state.segmentTasksLocations}
                  />
                );
              }
            }}
          />
          <Route
            path="/jobs/:id/tasks/timelog"
            render={({ location }) => {
              if (location.state) {
                return (
                  <TimeLogNew
                    timeLog={location?.state?.timeLog}
                    task={location?.state?.task}
                  />
                );
              }
            }}
          />
          <Route
            path="/jobs/:id/tasks/:taskId"
            render={({ location }) => {
              const { state } = location;
              if (state) return <TaskDetails task={state.task} />;
            }}
          />
          <Route
            path="/jobs/:id/report/dailyproduction"
            render={({ location }) => {
              // if(location.state){
              return <DailyCheckIn />;
              // }
            }}
          />
          <Route path="/jobs" exact component={Jobs} />
          <Route path="/jobs/new" component={JobNew} />
          <Route path="/jobs/:id/details" component={JobDetails} />
          <Route
            path="/jobs/:id/checkout/:date"
            component={JobEquipmentCheckoutList}
          />
          <Route
            path="/jobs/:id/checkin/:date"
            component={JobEquipmentCheckinList}
          />
          <Route
            path="/jobs/:id/bores/:boreId/edit"
            render={({ location }) => {
              if (location.state) {
                return <BoresNew boreEntity={location.state.bore} />;
              }
            }}
          />

          <Route
            path="/jobs/:id/bores/:boreId/borelogs/:date/:boreLogId/details"
            exact
            component={BoreLogsDetails}
          />
          <Route
            path="/jobs/:id/bores/:boreId/borelogs/:date/:boreLogId/edit"
            exact
            component={BoreLogsEdit}
          />
          <Route
            path="/jobs/:id/bores/:boreId/borelogs/new"
            exact
            component={BoreLogsNew}
          />
          <Route
            path="/jobs/:id/bores/:boreId/borelogs/:date"
            component={BoreLogs}
          />
          <Route
            path="/jobs/:jobId/locatetickets/new"
            exact
            render={({ location }) => {
              return renderNewLocateTicket(location);
            }}
          />
          <Route
            path="/jobs/:jobId/locatetickets/details"
            exact
            render={({ location }) => {
              return renderLocateTicketDetails(location);
            }}
          />
          <Route path="/jobs/:id/bores/:boreId" component={BoresDetails} />
          <Route path="/crews/:id/edit" component={CrewsEdit} />
          <Route path="/crews" exact component={Crews} />
          <Route path="/crews/new" exact component={CrewsNew} />
          <Route path="/crews/:id" exact component={CrewsDetails} />
          <Route path="/acceptinvite" exact component={Logout} />
          <Route path="/jobs/:jobId/crews/:id" exact component={CrewsDetails} />
          <Route
            path="/jobs/:jobId/crews/:id/edit"
            exact
            component={CrewsEdit}
          />
          <Route path="/calculators" exact component={Calculators} />
          <Route path="/soilconditions" exact component={SoilConditions} />
          <Route
            path="/locatetickets"
            exact
            render={({ location }) => {
              if (location.state) {
                return <LocateTicketContainer />;
              }
            }}
          />
          <Route
            path="/soilconditions/new"
            exact
            component={SoilConditionsNew}
          />
          <Route
            path="/soilconditions/:id"
            exact
            component={SoilConditionsDetails}
          />
          <Route
            path="/soilconditions/:id/edit"
            component={SoilConditionsEdit}
          />
          <Route
            path="/dumpsite/new"
            render={({ location }) => {
              return <DumpSite dumpSiteProp={location?.state?.dumpSite} />;
            }}
          />
          <Route
            path="/dumpsite/:dumpsiteId"
            render={({ location }) => {
              if (location.state) {
                return (
                  <DumpSiteDetailsComponent
                    dumpSite={location.state.dumpSite}
                  />
                );
              }
            }}
          />
          <Route path="/notifications" exact component={Notifications} />
          <Route
            path="/notifications/:id/edit"
            exact
            component={NotificationsEdit}
          />
          <Route path="/notifications/new" exact component={NotificationsNew} />
          <Route
            path="/locatetickets/new"
            exact
            render={({ location }) => {
              return renderNewLocateTicket(location);
            }}
          />
          <Route
            path="/locatetickets/details"
            exact
            render={({ location }) => {
              return renderLocateTicketDetails(location);
            }}
          />
          <Route
            path="/jobs/:jobId/jobwalkthrough/details"
            render={({ location }) => {
              if (location.state) {
                return (
                  <JobWalkThroughDetails
                    jobWalkThroughItem={
                      location.state?.jobWalkThroughItem ?? ""
                    }
                    jobId={location.state.jobId ?? ""}
                    jobWalkThroughId={location.state?.jobWalkThroughId ?? ""}
                  />
                );
              }
            }}
          />
          <Route
            path="/jobs/:jobId/jobwalkthrough/new"
            render={({ location }) => {
              if (location.state) {
                return (
                  <JobWalkThroughNew
                    jobWalkThroughItem={
                      location.state?.jobWalkThroughItem ?? ""
                    }
                    jobId={location.state.jobId ?? ""}
                    jobWalkThroughId={location.state?.jobWalkThroughId ?? ""}
                  />
                );
              }
            }}
          />

          <Redirect to="/" />
        </Switch>
      </PrivateLayout>
    </BrowserRouter>
  );

  if (!currentUser || messageState === "subscription-error") {
    routes = (
      <PublicLayout>
        <BrowserRouter>
          <Switch>
            <Route
              path="/landing"
              render={() => {
                return <LandingPage content={<Landing />} />;
              }}
            />
            <Route
              path="/join"
              render={() => {
                return <LandingPage content={<JoinCompany />} />;
              }}
            />
            <Route
              path="/signin"
              render={() => {
                return <LandingPage content={<Signin />} />;
              }}
            />
            <Route
              path="/signup"
              render={() => {
                return <LandingPage content={<Signup />} />;
              }}
            />
            <Route
              path="/acceptinvite"
              render={() => {
                return <LandingPage content={<AcceptInvite />} />;
              }}
            />
            <Route
              path="/resetpassword"
              render={() => {
                return <LandingPage content={<ResetPassword />} />;
              }}
            />
            <Redirect to="/landing" />
          </Switch>
        </BrowserRouter>
      </PublicLayout>
    );
  }

  const accountDetailsUpdateNeeded = currentUser && !currentUser.userProfile;
  if (accountDetailsUpdateNeeded) {
    routes = (
      <PublicLayout>
        <BrowserRouter>
          <Switch>
            <Route path="/signup" exact component={Signup} />
            <Redirect to="/signup" />
          </Switch>
        </BrowserRouter>
      </PublicLayout>
    );
  }

  if (showWelcomeScreen) {
    routes = (
      <PublicLayout>
        <BrowserRouter>
          <Switch>
            <Route
              path="/welcome"
              render={() => {
                return <LandingPage content={<WelcomeScreen />} />;
              }}
            />
            <Redirect to="/welcome" />
          </Switch>
        </BrowserRouter>
      </PublicLayout>
    );
  }

  if (currentUser && isTutorial) {
    let theRoute = null;
    let redirect = null;
    if (["addEquipment"].includes(tutorialStep)) {
      theRoute = <Route path="/equipment" exact component={Equipment} />;
      redirect = <Redirect to="/equipment" />;
    }

    if (
      [
        "addingEquipmentRequired",
        "addingEquipmentCategory",
        "addingEquipmentImage",
      ].includes(tutorialStep)
    ) {
      theRoute = <Route path="/equipment/new" exact component={EquipmentNew} />;
      redirect = <Redirect to="/equipment/new" />;
    }

    if (["addCrewmember"].includes(tutorialStep)) {
      theRoute = <Route path="/crewmembers" exact component={Crewmember} />;
      redirect = <Redirect to="/crewmembers" />;
    }

    if (["addingCrewmember"].includes(tutorialStep)) {
      theRoute = (
        <Route path="/crewmembers/new" exact component={CrewmemberNew} />
      );
      redirect = <Redirect to="/crewmembers/new" />;
    }

    if (["showReport"].includes(tutorialStep)) {
      theRoute = (
        <Route path="/crewmembers/:id" component={CrewmemberDetails} />
      );
      redirect = <Redirect to="/crewmembers/:id" />;
    }

    if (["welcomeMessage", "addJob"].includes(tutorialStep)) {
      theRoute = <Route path="/jobs" component={Jobs} />;
      redirect = <Redirect to="/jobs" />;
    }

    if (["addingJob"].includes(tutorialStep)) {
      theRoute = <Route path="/jobs/new" component={JobNew} />;
      redirect = <Redirect to="/jobs/new" />;
    }

    routes = (
      <BrowserRouter>
        <PrivateLayout>
          <Switch>
            {theRoute}
            {redirect}
          </Switch>
        </PrivateLayout>
      </BrowserRouter>
    );

    if (["jobReport", "bores", "addingJob"].includes(tutorialStep)) {
      routes = (
        <BrowserRouter>
          <PrivateLayout>
            <Switch>
              <Route path="/jobs/new" component={JobNew} />
              <Route path="/jobs/:id/details" component={JobDetails} />
              <Redirect to="/jobs/new" />
            </Switch>
          </PrivateLayout>
        </BrowserRouter>
      );
    }
  }

  return routes;
};

export default App;
