import React, { useContext, useEffect, useState } from "react";
import CheckOutInItem from "../../../../components/Layout/CheckOutInItem/CheckOutInItem";
import {
  TaskEntity,
  TaskStatusType,
  showFootage,
  showQuantity,
  showYards,
} from "../../../../shared/data/taskmanagement/Task";
import { Link } from "react-router-dom";
import { parseFromFirebaseDate } from "../../../../shared/Util";
import { fromRGBValue } from "../../../../shared/Util";
import { saveTask } from "../../../../shared/api/TaskManagerApi";
import { AuthContext } from "../../../../shared/Auth";
import { Location } from "../../../../shared/data/Location";
import "./Task.css";
import { CrewEntity } from "../../../../shared/data/Crew";

interface TaskProps {
  task?: TaskEntity | null;
  allCrews?: CrewEntity[] | null;
  segmentStartLocation?: Location | null;
  segmentEndLocation?: Location | null;
  segmentTasksLocations?: Location[] | null;
  canEdit: boolean;
  onTaskDeleted?: (task: TaskEntity) => void;
}

export const TaskItemComponent = ({
  task = null,
  allCrews = null,
  segmentStartLocation = null,
  segmentEndLocation = null,
  segmentTasksLocations = null,
  canEdit,
  onTaskDeleted = () => {},
}: TaskProps) => {
  const context = useContext(AuthContext);
  const [taskState, setTaskState] = useState<TaskEntity | null>(task);

  useEffect(() => {
    setTaskState(() => task);
  }, [task]);

  const changeTaskState = async (
    task: TaskEntity,
    taskStatus: TaskStatusType
  ) => {
    const inventoryId = context.currentUser.company.inventoryID;
    const result = await saveTask(inventoryId, {
      ...task,
      taskStatus: taskStatus,
    });
    setTaskState(() => {
      return result.data ?? task;
    });
  };

  const showInProgressOption = () => {
    return (
      taskState?.taskStatus == "open" ||
      (taskState?.taskStatus == "onHold" &&
        (taskState?.currentDuration ?? 0) > 0)
    );
  };

  const showOpenOption = () => {
    return (
      taskState?.taskStatus == "closed" ||
      (taskState?.taskStatus == "onHold" &&
        (taskState?.currentDuration ?? 0) == 0)
    );
  };

  const showOnHoldOption = () => {
    return (
      taskState?.taskStatus != "onHold" && taskState?.taskStatus != "closed"
    );
  };

  function getDuration(): number {
    if (!taskState?.estimatedDuration || taskState?.currentDuration == 0) {
      return 0;
    } else
      return (
        ((taskState?.currentDuration ?? 0) /
          (taskState?.estimatedDuration ?? 1)) *
        100
      );
  }

  function getFootage(): number {
    if (!taskState?.expectedFootage || taskState?.currentFootage == 0) {
      return 0;
    } else
      return (
        ((taskState?.currentFootage ?? 0) / (taskState?.expectedFootage ?? 1)) *
        100
      );
  }

  function getFilledYards(): number {
    if (!taskState?.expectedFilledYards || taskState?.currentFilledYards == 0) {
      return 0;
    } else
      return (
        ((taskState?.currentFilledYards ?? 0) /
          (taskState?.expectedFilledYards ?? 1)) *
        100
      );
  }

  function getQuantity(): number {
    if (!taskState?.expectedQuantity || taskState?.currentQuantity == 0) {
      return 0;
    } else
      return (
        ((taskState?.currentQuantity ?? 0) /
          (taskState?.expectedQuantity ?? 1)) *
        100
      );
  }

  function getTaskStatusColor(task?: TaskEntity | null): string {
    const timeExceeded =
      (task?.estimatedDuration ?? 0) < (task?.currentDuration ?? 0);
    switch (task?.taskStatus) {
      case "inProgress": {
        if (timeExceeded) return "task-status-in-progress-time-exceeded";
        else return "task-status-in-progress";
      }
      case "closed":
        return "task-status-completed";
      case "onHold":
        return "task-status-on-hold";
      case "open":
        return "task-status-open";
      default:
        return "";
    }
  }

  function findCrewByCrewId(crewID: String) {
    return allCrews?.find((crew) => {
      return crew.id === crewID;
    });
  }

  return (
    <React.Fragment key={`task-item-${taskState?.taskID}`}>
      <div
        className={`d-flex d-flex-row mb-2 pl-5 pt-5 align-items-center ${getTaskStatusColor(
          taskState
        )}`}
      >
        <Link
          to={{
            pathname: `/jobs/${taskState?.jobID}/tasks/${taskState?.id}`,
            state: {
              task: taskState,
            },
          }}
          href="#"
          className="flex-grow-1"
        >
          <div className="">
            <h6 className="font-weight-bold text-dark">{taskState?.title}</h6>
            <h6 className="font-weight-bold text-dark">
              ID: #{taskState?.taskID}
            </h6>
            <h6 className="text-dark-65 font-weight-bold">
              {`Start date: ${
                parseFromFirebaseDate(taskState?.startDate) ?? ""
              }`}
            </h6>
            <h6 className="font-weight-bold text-dark">{`Estimated time: ${taskState?.estimatedDuration?.toFixed(
              1
            )}h`}</h6>
            <h6 className="text-dark-65 font-weight-bold">
              {`Spent time: ${taskState?.currentDuration?.toFixed(1) ?? 0}h`}
            </h6>
            <div>
              {taskState?.crews?.map((crew, index) => {
                const crw = findCrewByCrewId(crew);
                if (crw != null) {
                  return (
                    <div style={{ position: "relative", display: "flex" }}>
                      <span
                        className="dot"
                        style={{
                          backgroundColor:
                            "rgba(" +
                            fromRGBValue(crw.red) +
                            ", " +
                            fromRGBValue(crw.green) +
                            ", " +
                            fromRGBValue(crw.blue) +
                            ", " +
                            crw.alpha +
                            ")",
                        }}
                      ></span>
                      <h6 className="text-dark-65 font-weight-bold ml-2">
                        {crw.crewName}
                      </h6>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </Link>
        <div className="d-flex flex-column align-items-center">
          <h6>Duration</h6>
          <CheckOutInItem
            id={Math.random() * 1000}
            percentage={getDuration().toFixed(1)}
          />
        </div>
        {showFootage(taskState ?? undefined) && (
          <>
            <div className="d-flex flex-column align-items-center">
              <h6>Footage Installed</h6>
              <CheckOutInItem
                id={Math.random() * 1000}
                percentage={getFootage().toFixed(1)}
              />
            </div>
          </>
        )}
        {showYards(taskState ?? undefined) && (
          <>
            <div className="d-flex flex-column align-items-center">
              <h6>Quantitiy Installed</h6>
              <CheckOutInItem
                id={Math.random() * 1000}
                percentage={getFilledYards().toFixed(1)}
              />
            </div>
          </>
        )}
        {showQuantity(taskState ?? undefined) && (
          <>
            <div className="d-flex flex-column align-items-center">
              <h6>{`Footage \nArchived`}</h6>
              <CheckOutInItem
                id={Math.random() * 1000}
                percentage={getQuantity().toFixed(1)}
              />
            </div>
          </>
        )}
        <div
          className="dropdown dropdown-inline ml-2"
          data-toggle="tooltip"
          title="Quick actions"
          data-placement="left"
        >
          <a
            className="btn btn-hover-light-primary btn-sm btn-icon"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i className="ki ki-bold-more-hor" />
          </a>
          <div className="dropdown-menu p-0 m-0 dropdown-menu-md dropdown-menu-right">
            <ul className="navi navi-hover">
              <li className="navi-item">
                <Link
                  to={{
                    pathname: `/jobs/${taskState?.jobID}/tasks/${taskState?.id}`,
                    state: {
                      task: taskState,
                    },
                  }}
                  href="#"
                  className="navi-link"
                >
                  <span className="navi-text">View Details</span>
                </Link>
              </li>
              {canEdit && (
                <li className="navi-item">
                  <Link
                    to={{
                      pathname: `/jobs/${taskState?.jobID}/taskmanagement/task`,
                      state: {
                        taskDateMin: undefined,
                        taskDateMax: undefined,
                        task: taskState,
                        segmentStartLocation: segmentStartLocation,
                        segmentEndLocation: segmentEndLocation,
                        segmentTasksLocations: segmentTasksLocations,
                      },
                    }}
                    href="#"
                    className="navi-link"
                  >
                    <span className="navi-text">Edit Task</span>
                  </Link>
                </li>
              )}
              {canEdit ? (
                <div>
                  {taskState?.taskStatus != "closed" && (
                    <li className="navi-item">
                      <a
                        onClick={() => {
                          if (taskState) changeTaskState(taskState, "closed");
                        }}
                        className="navi-link"
                      >
                        <span className="navi-text">Complete Task</span>
                      </a>
                    </li>
                  )}
                  {showInProgressOption() && (
                    <li className="navi-item">
                      <a
                        onClick={() => {
                          if (taskState)
                            changeTaskState(taskState, "inProgress");
                        }}
                        className="navi-link"
                      >
                        <span className="navi-text">Change to In progress</span>
                      </a>
                    </li>
                  )}
                  {showOnHoldOption() && (<li className="navi-item">
                    <a
                      onClick={() => {
                        if (taskState) changeTaskState(taskState, "onHold");
                      }}
                      className="navi-link"
                    >
                      <span className="navi-text">Set On Hold</span>
                    </a>
                  </li>)}
                </div>
              ) : (
                <div></div>
              )}
              {showOpenOption() ? (
                <li className="navi-item">
                  <a
                    onClick={() => {
                      if (taskState) changeTaskState(taskState, "open");
                    }}
                    className="navi-link"
                  >
                    <span className="navi-text">Open Task</span>
                  </a>
                </li>
              ) : (
                <div></div>
              )}
              {canEdit && (
                <li className="navi-item">
                  <a
                    onClick={() => {
                      if (taskState) onTaskDeleted(taskState);
                    }}
                    className="navi-link"
                  >
                    <span className="navi-text">Delete Task</span>
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TaskItemComponent;
