import React, { useContext, useEffect, useState } from "react";
import EQInputText from "../../../components/Form/Inputs/EQInputText";
import EQDatePicker from "../../../components/Form/Inputs/EQDatePicker";
import EQLabel from "../../../components/Form/Inputs/EQLabel";
import { JobWalkThroughEntity } from "../../../shared/data/job/JobWalkThrough";
import { showToast, toFirebaseDate } from "../../../shared/Util";
import DropzoneInput from "../../../components/Form/Inputs/DropzoneInput";
import MultipleItems from "../../../components/Carousels/MultipleItems";
import { FileEntity } from "../../../shared/data/folder/FolderStructure";
import moment from "moment";
import { Timestamp } from "firebase/firestore";
import { isImageFile } from "../../../shared/api/FilesApi";
import { ContextEntity } from "../../../shared/data/context/ContextEntity";
import { AuthContext } from "../../../shared/Auth";
import { saveJobWalkThrough } from "../../../shared/api/JobApi";
import JobWalkThroughAttendeeComponent from "./JobWalkThroughAttendee";
import { useHistory } from "react-router";

interface JobWalkThroughNewProp {
  jobId: string;
}

interface FormState {
  clearSignature: boolean;
  saving: boolean;
}

const JobWalkThroughNew = (props: JobWalkThroughNewProp) => {
  const context: ContextEntity = useContext(AuthContext);
  const history = useHistory();

  const [formState, setFormState] = useState<FormState>({
    clearSignature: false,
    saving: false,
  });
  const [walkThrough, setWalkThrough] = useState<JobWalkThroughEntity>();
  const [files, setFiles] = useState<FileEntity[]>();

  useEffect(() => {
    setFormState((previousState) => {
      return { ...previousState, clearSignature: true };
    });
  }, [walkThrough, files]);

  const onAddFile = async (acceptedFiles: any) => {
    await Promise.all(
      acceptedFiles.map(async (file: File, index: number) => {
        const url = window.URL.createObjectURL(file);
        const createdAt = Timestamp.fromMillis(
          Timestamp.now().toMillis() + index * 5000
        );

        const fileEntity: FileEntity = {
          id: null,
          name: "",
          fileName: file.name ?? "",
          fileType: isImageFile(file.name ?? "") ? "image" : "document",
          createdAt: createdAt,
          createdBy: context.currentUser?.displayName,
          url: url,
          file: file,
        };

        setFiles((prevFiles) => [...(prevFiles ?? []), fileEntity]);
      })
    );
  };

  const changeFileName = async (fileName: string, fileIndex: number) => {
    const fileToChangeName = files?.find((_, index) => index == fileIndex);
    if (fileToChangeName) {
      const newFiles = files?.map((file, index) => {
        if (index === fileIndex) {
          file.name = fileName;
        }
        return file;
      });
      setFiles(newFiles);
    }
  };

  const isFormValid = () => {
    const isAttendeesValid =
      !walkThrough?.attendees?.find(
        (attendee) => !attendee.name || !attendee.company
      ) && (walkThrough?.attendees?.length ?? 0) > 0;
    if (!walkThrough?.title || !walkThrough?.createdAt || !isAttendeesValid) {
      return false;
    }
    return true;
  };

  const save = async () => {
    setFormState((previousState) => {
      return { ...previousState, clearSignature: true };
    });
    try {
      if (walkThrough) {
        const result = await saveJobWalkThrough(
          context.currentUser?.userProfile?.companyID ?? "",
          context.currentUser?.company?.inventoryID ?? "",
          props.jobId,
          walkThrough,
          files
        );
      }
    } catch (error: any) {
      showToast("danger", "Error saving job walk through");
      setFormState((previousState) => {
        return { ...previousState, clearSignature: false };
      });
    }
  };

  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
        <h3 className="mb-10 font-weight-bold text-dark">
            New Job Walk Through
          </h3>
          <EQInputText
            title="Title"
            placeholder="Title"
            required={true}
            onOnInputChange={(result) => {
              setWalkThrough((previousState) => {
                return { ...previousState, title: result?.toString() };
              });
            }}
          />
          <EQDatePicker
            title="Date and Time"
            required={true}
            onDateSelected={(date) => {
              setWalkThrough((previousState) => {
                return { ...previousState, createdAt: toFirebaseDate(date) };
              });
            }}
          />
          <div
            key="task-new-products"
            className="form-group card card-border p-2"
          >
            <div className="mb-5">
              <EQLabel label="" title="Attendees" />
              <a
                role="button"
                onClick={() => {
                  setWalkThrough((previousState) => {
                    const attendees = [...(previousState?.attendees ?? []), {}];
                    return { ...previousState, attendees: attendees };
                  });
                }}
                className="navi-link"
              >
                Add attendee
              </a>
            </div>

            {walkThrough?.attendees?.map((attendee, index) => {
              return (
                <JobWalkThroughAttendeeComponent
                  clearSignature={formState?.clearSignature ?? false}
                  componentIdex={index}
                  attendee={attendee}
                  onRemove={() => {
                    setWalkThrough((previousState) => {
                      const updatedAttendees = (
                        previousState?.attendees ?? []
                      ).filter((_, idx) => idx !== index);
                      return {
                        ...previousState,
                        attendees: updatedAttendees,
                      };
                    });
                  }}
                  onChange={(attendeeChanged, signature) => {
                    setWalkThrough((previousState) => {
                      const updatedAttendees = (
                        previousState?.attendees ?? []
                      ).map((item, idx) =>
                        idx === index ? attendeeChanged : item
                      );
                      return {
                        ...previousState,
                        attendees: updatedAttendees,
                      };
                    });
                  }}
                />
              );
            })}
          </div>
          <EQInputText
            title="Notes"
            placeholder="Notes"
            numberOfLines={4}
            hint="Details about job walk"
            onOnInputChange={(result) => {
              setWalkThrough((previousState) => {
                return { ...previousState, notes: result?.toString() };
              });
            }}
          />
          <DropzoneInput
            {...{
              field: {
                onDrop: onAddFile,
              },
              supportedTypes: "Photos or Documents",
            }}
          />
          <div className="d-flex flex-row">
            <div className="flex-grow-1 min-w-0 mt-7">
              {" "}
              <MultipleItems
                slidesToShow={9}
                laptop={7}
                tablet={5}
                mobile={2}
                editFile={true}
                onNameChanged={(value: string, index: number) => {
                  changeFileName(value, index);
                }}
                images={files?.map((fileEntity) => {
                  return {
                    image: fileEntity.url ?? "",
                    title: fileEntity.fileName,
                    name: fileEntity.name ?? "",
                    createdAt: moment(fileEntity.createdAt?.toDate()).format(
                      "MM/DD/YY, hh:mma"
                    ),
                    uploadedBy: fileEntity.createdBy,
                  };
                })}
                removePhoto={(index: number) => {
                  const newFiles = files?.filter((_, idx) => idx !== index);
                  setFiles(newFiles);
                }}
              />
            </div>
          </div>
          <div className="justify-content-between border-top mt-5 pt-10">
            <button
              key="segment-new-btn-save"
              className={
                formState.saving
                  ? "btn btn-primary font-weight-bold mr-4 py-4 spinner spinner-white spinner-right"
                  : "btn btn-primary font-weight-bold mr-4 py-4 px-9"
              }
              disabled={!isFormValid()}
              onClick={(event) => {
                event.preventDefault();
                save();
              }}
            >
              Save
            </button>
            <button
              onClick={() => {
                history.goBack();
              }}
              className="btn btn-secondary font-weight-bold text-uppercase px-7 py-4"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default JobWalkThroughNew;
