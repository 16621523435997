import React, { useContext, useEffect, useState } from "react";
import { JobEntity } from "../../../shared/data/job/Job";
import { ContextEntity } from "../../../shared/data/context/ContextEntity";
import { AuthContext } from "../../../shared/Auth";
import { getJobWalkThroughList } from "../../../shared/api/JobApi";
import { JobWalkThroughList } from "./JobWalkThroughList";
import { JobWalkThroughEntity } from "../../../shared/data/job/JobWalkThrough";
import { Link } from "react-router-dom";

interface JobWalkThroughContainerProps {
  job: JobEntity;
  setTabActive: (task: string) => void;
}

interface FormState {
  jobWalkThroughList: JobWalkThroughEntity[];
  isLoading: boolean;
}

export const JobWalkThroughContainer = (
  props: JobWalkThroughContainerProps
) => {
  const authContext: ContextEntity = useContext(AuthContext);

  const [formState, setFormState] = useState<FormState>({
    jobWalkThroughList: [],
    isLoading: false,
  });

  const fetchData = async () => {
    setFormState((previousState) => {
      return { ...previousState, isLoading: true };
    });

    const user = authContext.currentUser;
    const response = await getJobWalkThroughList(
      user?.company?.inventoryID ?? "",
      props?.job?.id ?? ""
    );

    setFormState((previousState) => {
      return {
        ...previousState,
        jobWalkThroughList: response.data ?? [],
        isLoading: false,
      };
    });
  };

  useEffect(() => {
    fetchData();
    props.setTabActive("jobwalkthrough");
  }, []);

  return (
    <React.Fragment>
      {formState.isLoading ? (
        <div className="spinner-center mt-10 spinner spinner-primary spinner-lg" />
      ) : formState?.jobWalkThroughList?.length == 0 ? (
        <div className="pt-5">
          <h1 className="text-center">No Job Walk Through Found</h1>
          <div className="text-center mt-4">
            Start tracking walk throughs for those job by adding tem to
            Equappment
          </div>
          <div className="text-center">
            <Link
              className="btn btn-primary font-weight-bolder mt-8"
              to={{
                pathname: `/jobs/${props.job.id}/jobwalkthrough/new`,
                state: {
                  jobId: props.job.id,
                },
              }}
              href="#"
            >
              CREATE JOB WALK THROUGH
            </Link>
          </div>
        </div>
      ) : (
        <div>
          <div className="d-flex flex-column flex-grow-1">
            <div className="d-flex flex-row form-group row-col-2 mx-0 float-right">
              <Link
                to={{
                  pathname: `/jobs/${props.job.id}/jobwalkthrough/new`,
                  state: {
                    jobId: props.job.id,
                  },
                }}
                className="btn btn-primary font-weight-bold mr-4"
              >
                New job walk through
              </Link>
            </div>
          </div>
          <JobWalkThroughList
            job={props.job}
            jobWalkThroughList={formState?.jobWalkThroughList ?? []}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default JobWalkThroughContainer;
