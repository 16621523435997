import React, { useContext, useEffect } from "react";
import useLocateTickets from "../../hooks/LocateTickets/LocateTicketHook";
import { AuthContext } from "../../shared/Auth";
import { ContextEntity } from "../../shared/data/context/ContextEntity";
import LocateTicketListComponent from "./list/LocateTicketListComponent";
import { Link } from "react-router-dom";
import { LocateTicketEntity } from "../../shared/data/LocateTickets";
import $ from 'jquery'

const LocateTicketContainer = () => {
  const authContext: ContextEntity = useContext(AuthContext);

  const { locateTickets, jobs } = useLocateTickets(
    authContext.currentUser?.company?.inventoryID??""
  );

  const clearModals = () => {
    $("body").removeClass("modal-open");
    $(".modal-backdrop").remove();
  };

  useEffect(()=>{
    clearModals()
  },[])

  return (
    <React.Fragment>
      <div className="d-flex flex-column flex-grow-1">
        <div className="d-flex flex-row form-group row-col-2 mx-0 float-right">
          <Link
            to={{
              pathname: "/locatetickets/new",
              state: {
                locateTicket: new LocateTicketEntity(
                  null,
                  authContext.currentUser?.userProfile?.companyID,
                  authContext.currentUser?.company?.inventoryID
                ),
                jobs: jobs
              },
            }}
            className="btn btn-primary font-weight-bold mr-4"
          >
            New locate ticket
          </Link>
        </div>
      </div>
      <LocateTicketListComponent locateTickets={locateTickets} jobs={jobs} hideOptions={true} onRefreshList={()=>{
        
      }} />
    </React.Fragment>
  );
};

export default LocateTicketContainer;
