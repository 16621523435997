import { Timestamp } from "firebase/firestore"
import { getFileData } from "../../shared/api/FilesApi"
import { getJobWalkThroughList } from "../../shared/api/JobApi"
import { buildJobWalkThroughFileEntityPath, buildJobWalkThroughFilesPath } from "../../shared/data/job/JobWalkThrough"
import { useState } from "react"
import { FileEntity } from "../../shared/data/folder/FolderStructure"

function useJobWalkThroughFiles(){

    // const [files, setFiles] = useState<FileEntity[]>([])


    const loadAllFiles = async (companyId: string, inventoryId: string, jobId: string) => {
        try {
            const jobWalkThroughs = await getJobWalkThroughList(inventoryId, jobId)
            const rootFolderId = Math.random().toString()

            const jobWalkThroughFolder: any = [];

            await Promise.all(
                jobWalkThroughs?.data?.map(async (jobWalkThrough) => {
                    const walkThroughFiles = await getFileData(
                        buildJobWalkThroughFileEntityPath(inventoryId, jobId, jobWalkThrough.id ?? ""),
                        buildJobWalkThroughFilesPath(companyId, jobId, jobWalkThrough.id ?? ""))

                    jobWalkThroughFolder.push({
                        categoryId: rootFolderId,
                        id: jobWalkThrough.id,
                        children: walkThroughFiles?.data ?? [],
                        display: jobWalkThrough.title ?? "No Walk Through Title",
                        name: jobWalkThrough.title ?? "No Walk Through Title",
                        subfolders: [],
                        level: 2,
                        uploadedAt: Timestamp.now(),
                        type: "folder",
                        readOnly: true
                    });

                }) ?? []
            )
            return jobWalkThroughFolder

        } catch (error: any) {
            console.error(`Error loading job walk through files ${error}`)
            return []
        }
    }

    return { loadAllFiles }

}


export const getAllJobWalkThroughFiles = async (companyId: string, inventoryId: string, jobId: string) => {
    try {
        const jobWalkThroughs = await getJobWalkThroughList(inventoryId, jobId)
        const rootFolderId = Math.random().toString()

        const jobWalkThroughFolder: any = [];

        await Promise.all(
            jobWalkThroughs?.data?.map(async (jobWalkThrough) => {
                const walkThroughFiles = await getFileData(
                    buildJobWalkThroughFileEntityPath(inventoryId, jobId, jobWalkThrough.id ?? ""),
                    buildJobWalkThroughFilesPath(companyId, jobId, jobWalkThrough.id ?? ""))

                jobWalkThroughFolder.push({
                    categoryId: rootFolderId,
                    id: jobWalkThrough.id,
                    children: walkThroughFiles?.data ?? [],
                    files: walkThroughFiles?.data ?? [],
                    display: jobWalkThrough.title ?? "No Walk Through Title",
                    name: jobWalkThrough.title ?? "No Walk Through Title",
                    subfolders: [],
                    level: 2,
                    uploadedAt: Timestamp.now(),
                    type: "folder",
                    readOnly: true
                });

            }) ?? []
        )

        const locateTicketsFiles = jobWalkThroughFolder
        ?.filter((jobWalkThrough:any) => jobWalkThrough.children?.length > 0)
        ?.map((jobWalkThrough:any) => {
          const files = jobWalkThrough.children?.filter((child:any)=>(child.fileType=="document")).map((child:any) => ({
            categoryId: jobWalkThrough.id,
            name: child.name,
            filename: child.fileName,
            description: child.notes,
            fileLink: child.url,
            fileType:"document",
            type: child.fileType,
            readOnly:true,
            subfolders:[],
            createdBy: child.createdBy,
            uploadedAt: child.createdAt,
          }));
  
          return {...jobWalkThrough, files:files};
        });

        return {
            categoryId: null,
            id: rootFolderId,
            display: "Job Walk Through",
            name: "Job Walk Through",
            subfolders: locateTicketsFiles.filter((file:any)=>(file.files?.length>0 || file.subfolders?.length>0)),
            level: 1,
            type: "folder",
            readOnly:true
          };


    } catch (error: any) {
        console.error(`Error loading job walk through files ${error}`)
        return []
    }
}

export default useJobWalkThroughFiles