import React, { useContext, useEffect, useMemo, useState } from "react";
import GoogleMapReact from "google-map-react";
import {
  buildTimeLogFileEntityPath,
  buildTImeLogFilesPath,
  buildTimeLogFileUploadPath,
  TimeLogEntity,
} from "../../../../shared/data/taskmanagement/TimeLog";
import { parseFromFirebaseDate, showToast } from "../../../../shared/Util";
import { Timestamp } from "firebase/firestore";
import DropzoneInput from "../../../../components/Form/Inputs/DropzoneInput";
import { saveTask, saveTimeLog } from "../../../../shared/api/TaskManagerApi";
import { AuthContext } from "../../../../shared/Auth";
import moment from "moment";
import { useHistory } from "react-router-dom";
import {
  TaskEntity,
  showFootage,
  showQuantity,
  showYards,
} from "../../../../shared/data/taskmanagement/Task";
import MultipleItems from "../../../../components/Carousels/MultipleItems";
import EQMarker from "../../../../components/GoogleMap/EQMarker";
import Geosuggest from "react-geosuggest";
import {
  FileEntity,
  toFileEntity,
  toFileEntityDto,
} from "../../../../shared/data/folder/FolderStructure";
import {
  FileUploadEntity,
  getFileData,
  isImageFile,
  saveFileData,
  saveFileEntity,
} from "../../../../shared/api/FilesApi";
import { ContextEntity } from "../../../../shared/data/context/ContextEntity";

export interface TimeLogProps {
  timeLog: TimeLogEntity;
  task: TaskEntity;
}

export const TimeLogNew = ({ timeLog, task }: TimeLogProps) => {
  const authContext: ContextEntity = useContext(AuthContext);
  const history = useHistory();

  const [formState, setFormState] = useState({
    mapTypeId: "satellite",
    isSaving: false,
  });

  const taskMemo = useMemo<TaskEntity>(() => {
    return {
      ...task,
      currentDuration: (task.currentDuration ?? 0) - (timeLog?.duration ?? 0),
      currentFilledYards:
        (task.currentFilledYards ?? 0) - (timeLog?.yards ?? 0),
      currentFootage: (task.currentFootage ?? 0) - (timeLog?.footage ?? 0),
      currentQuantity: (task.currentQuantity ?? 0) - (timeLog?.quantity ?? 0),
    };
  }, []);

  const [timeLogState, setTimeLogState] = useState<TimeLogEntity>({
    ...timeLog,
    startDate: timeLog?.startDate ?? Timestamp.fromDate(new Date()),
    lat: task.lat,
    lng: task.lng,
  });
  const [files, setFiles] = useState<FileEntity[]>([]);

  const save = async () => {
    setFormState((previousState) => {
      return { ...previousState, isSaving: true };
    });
    try {
      const user = authContext.currentUser;
      const response = await saveTimeLog(
        user?.company?.inventoryID ?? "",
        timeLogState
      );

      //we upload files for time log after time log has been saved
      const timeLogId = response?.data?.id;
      if (timeLogId) {
        //After time log is saved we upload all files without id
        const filesToUpload = files?.filter((file) => !file.id);
        await Promise.all(
          filesToUpload?.map(async (file) => {
            await uploadFile(file, timeLogId);
          }) ?? []
        );
      }

      //after changing/adding time log we need to update related task
      await saveTask(user?.company?.inventoryID ?? "", buildTask());
      if (response.data?.id) {
        const timeLogWithNewImages = {
          ...timeLogState,
          imageTimeStamp: files[0]?.createdAt,
          photos: files?.map((file) => file.createdAt ?? Timestamp.now()) ?? [],
        };
        const timeLogResult = await saveTimeLog(
          user?.company?.inventoryID ?? "",
          timeLogWithNewImages
        );
      }
      history.goBack();
    } catch (error: any) {
      showToast("danger", `Error saving Time Log: ${error}`);
    }
    setFormState((previousState) => {
      return { ...previousState, isSaving: false };
    });
  };

  const onRemoveFile = async (fileIndex: number) => {
    const fileToDelete = files?.find((_, index) => index == fileIndex);
    setFiles((prevFiles) =>
      prevFiles?.filter((_, index) => index !== fileIndex)
    );

    //We remove file from the list. In case this is existing locate ticket we try to remove file from backend too
    if (timeLogState?.id && fileToDelete) {
      const fileUpload: FileUploadEntity = {
        fileEntityPath: buildTimeLogFileEntityPath(
          authContext.currentUser?.company?.inventoryID ?? "",
          timeLogState?.id ?? ""
        ),
        file: null,
        fileUploadState: "delete",
        fileUploadPath: buildTimeLogFileUploadPath(
          authContext.currentUser?.userProfile?.companyID ?? "",
          timeLogState.id ?? "",
          fileToDelete?.createdAt?.seconds?.toString() ?? ""
        ),
        fileEntityDto: toFileEntityDto(fileToDelete),
        fileDocumentId: fileToDelete.id,
      };
      await saveFileData(fileUpload);
    }
  };

  //This method is to keep backward compatibility. Whenever there is a change on files for existing timelog we update
  //timelog entity by adding list of files in photos filed with file createdAt timestamp and we add imageTimeStamp filed with
  //first file in the list
  const updateTimeLogAfterFilesHaveBeenChanged = async () => {
    if (timeLogState.id) {
      const timeLogWithNewImages = { ...timeLogState };
      timeLogWithNewImages.photos =
        files?.map((file) => file.createdAt ?? Timestamp.now()) ?? null;
      timeLogWithNewImages.imageTimeStamp = files[0]?.createdAt ?? null;

      await saveTimeLog(
        authContext.currentUser?.company?.inventoryID ?? "",
        timeLogWithNewImages
      );
    }
  };

  const onAddFile = async (acceptedFiles: any) => {
    await Promise.all(
      acceptedFiles.map(async (file: File, index: number) => {
        const url = window.URL.createObjectURL(file);
        const createdAt = Timestamp.fromMillis(
          Timestamp.now().toMillis() + index * 5000
        );

        const fileEntity: FileEntity = {
          id: null,
          name: "",
          fileName: file.name ?? "",
          fileType: isImageFile(file.name ?? "") ? "image" : "document",
          createdAt: createdAt,
          createdBy: authContext.currentUser?.displayName,
          url: url,
          file: file,
        };

        //In case this is existing ticket we upload file as soon as it is added and save file entity in locate ticket.
        //In case this is new locate ticket we add file to file list and upload it when new locate ticket is created
        if (timeLog?.id) {
          //TODO: add form state for uploading image to show some indicator in case file is too big.
          await uploadFile(fileEntity, timeLog.id);
        } else {
          setFiles((prevFiles) => [...(prevFiles ?? []), fileEntity]);
        }
      })
    );
  };

  const uploadFile = async (fileEntity: FileEntity, timeLogId: string) => {
    const fileUpload: FileUploadEntity = {
      fileEntityPath: buildTimeLogFileEntityPath(
        authContext.currentUser?.company?.inventoryID ?? "",
        timeLogId
      ),
      file: fileEntity.file,
      fileUploadState: "upload",
      fileUploadPath: buildTimeLogFileUploadPath(
        authContext.currentUser?.userProfile?.companyID ?? "",
        timeLogId,
        fileEntity?.createdAt?.seconds?.toString() ?? ""
      ),
      fileEntityDto: toFileEntityDto(fileEntity),
      fileDocumentId: null,
    };

    //TODO: add form state for uploading image to show some indicator in case file is too big.
    const result = await saveFileData(fileUpload);

    if (result?.data?.fileEntityDto) {
      const file = toFileEntity(
        result?.data?.fileEntityDto,
        result?.data?.fileDocumentId
      );
      setFiles((prevFiles) => [...(prevFiles ?? []), file]);
    }
  };

  const changeFileName = async (fileName: string, fileIndex: number) => {
    const fileToChangeName = files?.find((_, index) => index == fileIndex);
    if (fileToChangeName) {
      const newFiles = files?.map((file, index) => {
        if (index === fileIndex) {
          file.name = fileName;
        }
        return file;
      });
      setFiles(newFiles);
      await saveFileEntity(
        toFileEntityDto(fileToChangeName),
        buildTimeLogFileEntityPath(
          authContext.currentUser?.company?.inventoryID ?? "",
          timeLogState?.id ?? ""
        ),
        fileToChangeName.id
      );
    }
  };

  const buildTask = () => {
    const task: TaskEntity = {
      ...taskMemo,
      currentDuration:
        (taskMemo?.currentDuration ?? 0) + (timeLogState?.duration ?? 0),
      currentFilledYards:
        (taskMemo?.currentFilledYards ?? 0) + (timeLogState?.yards ?? 0),
      currentFootage:
        (taskMemo?.currentFootage ?? 0) + (timeLogState?.footage ?? 0),
      currentQuantity:
        (taskMemo?.currentQuantity ?? 0) + (timeLogState?.quantity ?? 0),
      taskStatus: "inProgress",
    };
    return task;
  };

  const getImages = async () => {
    const files = await getFileData(
      buildTimeLogFileEntityPath(
        authContext.currentUser?.company?.inventoryID ?? "",
        timeLog.id ?? ""
      ),
      buildTImeLogFilesPath(
        authContext.currentUser?.userProfile?.companyID ?? "",
        timeLog.id ?? ""
      )
    );
    setFiles(files?.data ?? []);
  };

  useEffect(() => {
    if (timeLog?.imageTimeStamp) getImages();
  }, []);

  useEffect(() => {
    if (timeLogState.id) updateTimeLogAfterFilesHaveBeenChanged();
  }, [files]);

  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <h3 className="mb-10 font-weight-bold text-dark">Time Log</h3>
          <div key="startDate" className="form-group">
            <label>Start Date and Time</label>
            <input
              type="datetime-local"
              className="form-control"
              value={parseFromFirebaseDate(
                timeLogState?.startDate ?? Timestamp.fromDate(new Date()),
                "YYYY-MM-DD HH:mm"
              )}
              onChange={(input) => {
                setTimeLogState((previousState) => {
                  return {
                    ...previousState,
                    startDate: Timestamp.fromDate(
                      moment(input.target.value).toDate()
                    ),
                  };
                });
              }}
            />
          </div>
          <div key="time-log-time-spent" className="form-group">
            <label>Time spent (h)</label>
            <input
              type="number"
              className="form-control"
              value={timeLogState?.duration ?? undefined}
              onChange={(input) => {
                setTimeLogState((previousState) => {
                  return {
                    ...previousState,
                    duration: parseFloat(input?.target?.value),
                  };
                });
              }}
            />
            <div className="form-text text-muted">
              Time spent in hours for this entry.
            </div>
          </div>
          {showFootage(task) && (
            <div key="time-log-footage-achieved" className="form-group">
              <label>Footage Achieved (ft)</label>
              <input
                type="number"
                className="form-control"
                value={timeLogState?.footage ?? undefined}
                onChange={(input) => {
                  setTimeLogState((previousState) => {
                    return {
                      ...previousState,
                      footage: parseFloat(input.target.value),
                    };
                  });
                }}
              />
              <div className="form-text text-muted">
                Footage achieved in feets on this time log.
              </div>
            </div>
          )}
          {showYards(task) && (
            <div key="time-log-yards-achieved" className="form-group">
              <label>Yards Achieved (yd)</label>
              <input
                type="number"
                className="form-control"
                value={timeLogState?.yards ?? undefined}
                onChange={(input) => {
                  setTimeLogState((previousState) => {
                    return {
                      ...previousState,
                      yards: parseInt(input.target.value),
                    };
                  });
                }}
              />
              <div className="form-text text-muted">
                Yards achieved in yards on this time log.
              </div>
            </div>
          )}
          {showQuantity(task) && (
            <div key="time-log-quantity-achieved" className="form-group">
              <label>Quantity Achieved</label>
              <input
                type="number"
                className="form-control"
                value={timeLogState?.quantity ?? undefined}
                onChange={(input) => {
                  setTimeLogState((previousState) => {
                    return {
                      ...previousState,
                      quantity: parseInt(input.target.value),
                    };
                  });
                }}
              />
              <div className="form-text text-muted">
                Quantity achieved on this time log.
              </div>
            </div>
          )}
          <div key="time-log-notes" className="form-group">
            <label>Notes</label>
            <textarea
              rows={3}
              className="form-control"
              value={timeLogState?.notes ?? undefined}
              onChange={(input) => {
                setTimeLogState((previousState) => {
                  return { ...previousState, notes: input.target.value };
                });
              }}
            />
            <div className="form-text text-muted">
              Detailed notes about this time log
            </div>
          </div>

          <Geosuggest
            autoActivateFirstSuggest={true}
            inputClassName="form-control"
            suggestsClassName="sug-container"
            suggestItemClassName="dropdown-item"
            initialValue={timeLogState?.address ?? ""}
            suggestItemActiveClassName="sug-item-active"
            onSuggestSelect={(result: any) => {
              setTimeLogState((previousState) => {
                return {
                  ...previousState,
                  lat: result?.location?.lat ?? 0.0,
                  long: result?.location?.lng ?? 0.0,
                  address: result?.label ?? "",
                };
              });
            }}
            autoComplete="off"
          />

          <div className="form-group mt-6"></div>

          <div style={{ height: "40vh", width: "100%" }}>
            <GoogleMapReact
              options={{
                mapTypeControl: true,
                mapTypeControlOptions: {
                  style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                },
                mapTypeId: formState.mapTypeId,
              }}
              bootstrapURLKeys={{
                key: process.env.REACT_APP_GOOGLE_MAP_KEY ?? "",
              }}
              center={{
                lat: timeLogState?.lat ?? 0,
                lng: timeLogState?.lng ?? 0,
              }}
              zoom={14}
              yesIWantToUseGoogleMapApiInternals={true}
              onGoogleApiLoaded={({ map, maps }) => {
                map.addListener("click", (e: any) => {
                  setTimeLogState((previousState) => {
                    return {
                      ...previousState,
                      lat: e.latLng.lat(),
                      lng: e.latLng.lng(),
                    };
                  });
                });
                map.addListener("maptypeid_changed", () => {
                  const mapTypeId = map.getMapTypeId() ?? "satellite";
                  setFormState((previousState) => {
                    if (mapTypeId != previousState.mapTypeId)
                      return { ...previousState, mapTypeId: mapTypeId };
                    else return previousState;
                  });
                });
              }}
            >
              <EQMarker
                key={103}
                lat={timeLogState?.lat}
                lng={timeLogState?.lng}
                pinColor={"darkgreen"}
              />
            </GoogleMapReact>
          </div>

          <div className="mt-10"></div>
          <DropzoneInput
            {...{
              field: {
                onDrop: onAddFile,
              },
            }}
          />
          <div className="mt-5 mb-5">
            <MultipleItems
              slidesToShow={9}
              laptop={7}
              tablet={5}
              mobile={2}
              editFile={true}
              images={files?.map((file) => {
                return {
                  image: file.url,
                  title: file.fileName,
                  name: file.name,
                  createdAt: moment(file.createdAt?.toDate()).format(
                    "MM/DD/YY, hh:mma"
                  ),
                  uploadedBy: file.createdBy,
                };
              })}
              onNameChanged={(value: string, index: number) => {
                changeFileName(value, index);
              }}
              removePhoto={(index: number) => {
                onRemoveFile(index);
                // const images = [...timeLogImages];
                // images.splice(index, 1);
                // setTimeLogImages(() => {
                //   return images;
                // });
              }}
            />
          </div>
          <div className="justify-content-between border-top pt-10">
            <button
              key="segment-new-btn-save"
              className={
                formState.isSaving
                  ? "btn btn-primary font-weight-bold text-uppercase px-14 py-4 mr-2 spinner spinner-white spinner-right"
                  : "btn btn-primary font-weight-bold text-uppercase px-12 py-4 mr-2"
              }
              disabled={formState.isSaving}
              onClick={(event) => {
                event.preventDefault();
                save();
              }}
            >
              Save
            </button>
            <button
              onClick={() => {
                history.goBack();
              }}
              className="btn btn-secondary font-weight-bold text-uppercase px-7 py-4"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TimeLogNew;
